import React from "react";
import useWebSockets from "./setupWebSocket";

const WebsocketComponent = () => {
    // Hook websockets
    useWebSockets();

    return null;
}

const WebSocketProvider = (props: any) => {
    const MemoWebSockets = React.memo(WebsocketComponent);

    return (
        <>
            <MemoWebSockets key={"ws"}/>
            {
                props.children
            }
        </>
    )
}

export {
    WebSocketProvider
}
