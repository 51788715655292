import {useLocation} from "react-router-dom";
import {
    useGetQuizQuestionApiQuizGet
} from "../../Client/default/default";
import React, {useContext, useEffect} from "react";
import {Heading} from "@chakra-ui/react";
import {EmptyMessage} from "../Utils/EmptyMessage";
import Loading from "../Utils/Loading";
import {AnimateMount} from "../Utils/Animation";
import {QuizInstanceQuestionsItem} from "../../Client/Models";
import {discriminateQuestionInstance} from "../Utils/QuestionDiscriminator";
import {
    GTDQuestionViewer,
    MultipleAnswerQuestionViewer,
    MultipleChoiceQuestionViewer,
    OpenQuestionViewer,
    QuestionMarkdownViewer
} from "./QuestionViewer";
import {LayoutContext} from "../../Layout";
import {WaitingForQuiz} from "./WaitingForQuiz";

export const SpectateQuestionInstance = (props: { instance : QuizInstanceQuestionsItem }) => {
    return discriminateQuestionInstance<React.ReactElement | null>(
        props.instance,
        {
            Blank : q => (<QuestionMarkdownViewer markdown={q.question.question}/>),
            Open : q => (<OpenQuestionViewer instance={q}/>),
            MultipleChoice : q => (<MultipleChoiceQuestionViewer instance={q}/>),
            MultipleAnswer :  q => (<MultipleAnswerQuestionViewer instance={q}/>),
            GuessTheDefinition : q => (<GTDQuestionViewer instance={q}/>),
            default : q => (<Heading>Dit type vraag is nog onder ontwikkeling...</Heading>)
        }
    )
}

export const Spectator = () => {
    const location = useLocation();
    const { size, setSize } = useContext(LayoutContext);

    let instance : QuizInstanceQuestionsItem | undefined = undefined;
    const {isLoading: isLoadingQuestion, isError: isErrorQuestion, data: questionData} = useGetQuizQuestionApiQuizGet();

    useEffect(() => {
        if (instance !== undefined) {
            setSize(instance.question.displaySize);
        }
    }, [setSize, instance, questionData]);

    if (isLoadingQuestion) {
        return <Loading/>
    }
    if (!questionData) {
        return <EmptyMessage text={"Error fetching data"}/>
    }

    if (questionData.status == 204) {
        return (
            <WaitingForQuiz/>
        )
    }

    instance = questionData.data! as QuizInstanceQuestionsItem;

    return (
        <AnimateMount customKey={instance._id!}>
            <SpectateQuestionInstance instance={instance}/>
        </AnimateMount>
    )
}
