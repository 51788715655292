import {themeGetter} from "./Theme";
import {ChakraProvider} from "@chakra-ui/react";
import {useAppSelector} from "./Store/Hooks";

export const CustomThemeProvider = (props: any) => {
    const font = useAppSelector((state) => state.font);

    const customProps = {
        styles: {
            global: {
                html: {
                    fontSize: `${font.size}px`
                }
            }
        },
    }

    return (
        <ChakraProvider theme={themeGetter(customProps)}>
            {props.children}
        </ChakraProvider>
    )
}
