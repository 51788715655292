import {useQueryClient} from 'react-query';
import {
    Button, Divider, Icon, IconButton,
    Stack
} from "@chakra-ui/react";
import React from "react";
import {
    createQuizApiTemplatesPut,
    duplicateQuizApiTemplatesDuplicatePut,
    getGetQuizApiTemplatesGetQueryKey,
    removeQuizApiTemplatesDelete,
    renameQuizApiTemplatesPatch,
    useGetQuizApiTemplatesGet
} from "../../../../Client/default/default";
import {Quiz} from "../../../../Client/Models";
import {EmptyMessage} from "../../../Utils/EmptyMessage";
import {useNavigate} from "react-router-dom";
import Loading from "../../../Utils/Loading";
import CustomList from "../../../Utils/CustomList";
import {MdContentCopy} from "react-icons/md";

const QuizBuilderSelector = () => {
    const {isLoading, isError, data: quizTemplates} = useGetQuizApiTemplatesGet();
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const createQuiz = () => {
        createQuizApiTemplatesPut(
            {name: "New Quiz", questions: []}
        ).then(r =>
            queryClient.invalidateQueries(getGetQuizApiTemplatesGetQueryKey())
        )
    }


    if (isLoading) {
        return <Loading/>;
    }
    if (!quizTemplates) {
        return <EmptyMessage text={"Error fetching quiz"}/>
    }

    return (
        <Stack>
            {quizTemplates.data.length > 0 &&
                <CustomList
                    elements={quizTemplates.data}
                    nameGetter={
                        (q: Quiz) => q.name
                    }
                    onRename={
                        (q: Quiz, name: string) => {
                            console.log(`Renaming quiz : ${q.name} to ${name}`);
                            renameQuizApiTemplatesPatch({
                                id: q._id!,
                                name: name
                            }).then(r =>
                                queryClient.invalidateQueries(getGetQuizApiTemplatesGetQueryKey())
                            );
                        }
                    }
                    onDelete={
                        (q: Quiz) => {
                            removeQuizApiTemplatesDelete({
                                id: q._id!,
                            }).then(r =>
                                queryClient.invalidateQueries(getGetQuizApiTemplatesGetQueryKey())
                            );
                        }
                    }
                    customElements={
                        (q: Quiz) => <IconButton
                            icon={<MdContentCopy/>}
                            variant='outline'
                            aria-label={"Duplicate"}
                            onClick={() => {
                                duplicateQuizApiTemplatesDuplicatePut({
                                    id: q._id!,
                                }).then(r =>
                                    queryClient.invalidateQueries(getGetQuizApiTemplatesGetQueryKey())
                                );
                            }
                            }
                        />
                    }
                    onClick={
                        (q: Quiz) => {
                            navigate('', {
                                state: {
                                    quizId: q._id!,
                                }
                            });
                        }
                    }
                />
            }
            {quizTemplates.data.length <= 0 &&
                (
                    <EmptyMessage text={"Er zijn nog geen quiz templates aangemaakt"}/>
                )
            }
            <Divider my={3}/>
            <Button variant='outline' onClick={createQuiz}>
                Quiz toevoegen
            </Button>
        </Stack>
    )
}

export {
    QuizBuilderSelector
}
