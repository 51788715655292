import {useQueryClient} from 'react-query';
import {
    Button,
    Divider,
    Stack
} from "@chakra-ui/react";
import React from "react";
import {
    createTeamApiTeamsPut, getGetTeamsApiTeamsGetQueryKey,
    removeTeamApiTeamsDelete,
    renameTeamApiTeamsPatch,
    useGetTeamsApiTeamsGet
} from "../../../../Client/default/default";
import {Team} from "../../../../Client/Models";
import {EmptyMessage} from "../../../Utils/EmptyMessage";
import Loading from "../../../Utils/Loading";
import CustomList from "../../../Utils/CustomList";

const TeamManager = () => {
    const {isLoading, isError, data: teams} = useGetTeamsApiTeamsGet();
    const queryClient = useQueryClient()

    const createTeam = () => {
        createTeamApiTeamsPut(
            {name: "New Team"}
        ).then(r =>
            queryClient.invalidateQueries(getGetTeamsApiTeamsGetQueryKey())
        )
    }

    if (isLoading) {
        return <Loading/>
    }

    return (
        <Stack>
            {teams && teams.data.length > 0 &&
                <CustomList
                    elements={teams.data}
                    nameGetter={(t : Team) => t.name}
                    onRename={(t : Team, name: string) => {
                        renameTeamApiTeamsPatch({
                            id: t._id!,
                            name: name
                        });
                    }}
                    onDelete={(t: Team) => {
                        removeTeamApiTeamsDelete({
                            id: t._id!,
                        }).then(r =>
                            queryClient.invalidateQueries(getGetTeamsApiTeamsGetQueryKey())
                        );
                    }}/>
            }
            {teams && teams.data.length <= 0 &&
                (
                    <EmptyMessage text={"Er zijn nog geen teams aangemaakt"}/>
                )
            }
            <Divider my={3}/>
            <Button variant='outline' onClick={createTeam}>
                Team toevoegen
            </Button>
        </Stack>
    )
}

export {
    TeamManager
}
