/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Quizy backend
 * OpenAPI spec version: 0.1.0
 */

/**
 * An enumeration.
 */
export type GTDQuestionStage = typeof GTDQuestionStage[keyof typeof GTDQuestionStage];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GTDQuestionStage = {
  COLLECTING_DEFINITIONS: 'COLLECTING_DEFINITIONS',
  DEFINITION_CHOICE: 'DEFINITION_CHOICE',
  SHOW_ANSWER: 'SHOW_ANSWER',
} as const;
