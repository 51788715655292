/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Quizy backend
 * OpenAPI spec version: 0.1.0
 */

/**
 * An enumeration.
 */
export type QuestionType = typeof QuestionType[keyof typeof QuestionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QuestionType = {
  BLANK: 'BLANK',
  OPEN: 'OPEN',
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
  MULTIPLE_ANSWER: 'MULTIPLE_ANSWER',
  GUESS_THE_DEFINITION: 'GUESS_THE_DEFINITION',
} as const;
