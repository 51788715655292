import {Button, Icon, Stack} from "@chakra-ui/react";
import React, {useContext, useEffect} from "react";
import {IconType} from "react-icons";
import {RiHammerFill, RiPlayFill, RiTeamFill} from "react-icons/ri";
import {useNavigate} from "react-router-dom";
import {LayoutContext} from "../../Layout";
import {DisplaySize} from "../../Client/Models";

interface AdminHomeItemProps {
    name: string;
    link: string
    icon: IconType;
}

const HomeItems: Array<AdminHomeItemProps> = [
    {name: 'Team management', link: 'teams', icon: RiTeamFill},
    {name: 'Quiz builder', link: 'builder', icon: RiHammerFill},
    {name: 'Quiz runner', link: 'runner', icon: RiPlayFill}
];

const AdminHome = () => {
    const {size, setSize} = useContext(LayoutContext);
    const navigate = useNavigate();

    useEffect(() => {
        setSize(DisplaySize.NORMAL)
    }, [setSize]);

    return (
        <Stack>
            {
                HomeItems.map((item: AdminHomeItemProps, i) =>
                    (
                        <Button key={`admin-home-item-${i}`}
                                leftIcon={<Icon as={item.icon}/>}
                                onClick={() => navigate(item.link)}>
                            {item.name}
                        </Button>
                    )
                )
            }
        </Stack>
    )
}

export {
    AdminHome
}
