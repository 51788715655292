import {BlankQuestion, DisplaySize, QuizQuestionsItem} from "../../../../../Client/Models";
import React from "react";
import {Box, Checkbox, Input, Select, VStack} from "@chakra-ui/react";
import MDEditor from "@uiw/react-md-editor";
import onImagePasted from "./ImagePaster";

const BaseQuestionEditor = (props: {
    question: BlankQuestion,
    onSetQuestion: (question: QuizQuestionsItem) => void
    hasShowAnswer: boolean
    children?: React.ReactNode
}) => {
    window.addEventListener("dragover",function(e){
        e.preventDefault();
    },false);
    window.addEventListener("drop",function(e){
        e.preventDefault();
    },false);

    const setMarkdown = (newValue: string | undefined) => {
        if (newValue != undefined) {
            props.onSetQuestion({...props.question, question: newValue})
        }
    }
    return (
        <VStack w={"100%"} align={"left"}>
            <Input
                placeholder='Question name'
                value={props.question.name}
                onChange={(e) =>
                    props.onSetQuestion({...props.question, name: e.target.value})
                }
            />
            <Select placeholder='Select display size'
                    value={props.question.displaySize}
                    onChange={(e: any) => props.onSetQuestion({...props.question, displaySize: e.target.value})}>
                <option value={DisplaySize.TINY}>Tiny</option>
                <option value={DisplaySize.SMALL}>Small</option>
                <option value={DisplaySize.NORMAL}>Normal</option>
                <option value={DisplaySize.LARGE}>Large</option>
                <option value={DisplaySize.XL}>Extra large</option>
                <option value={DisplaySize.XXL}>Extra extra large</option>
                <option value={DisplaySize.FULL_SCREEN}>Full screen</option>
            </Select>
            <Box w={"100%"}>
                <MDEditor
                    height={200}
                    value={props.question.question}
                    onPaste={async (event) => {
                        const result = await onImagePasted(event.clipboardData, setMarkdown);
                        if (result) {
                            event.preventDefault();
                        }
                    }}
                    onDrop={async (event) => {
                        await onImagePasted(event.dataTransfer, setMarkdown);
                        event.preventDefault();
                    }}
                    onChange={setMarkdown}
                />
            </Box>
            { props.hasShowAnswer &&
                <Checkbox
                    isChecked={props.question.isShowAnswer}
                    onChange={e => {
                        props.onSetQuestion({...props.question, isShowAnswer: e.target.checked})
                    }}>
                    Show Answer
                </Checkbox>
            }
            {props.children}
        </VStack>
    )
}

export {
    BaseQuestionEditor
}
