import {useEffect} from "react";
import {io} from "socket.io-client";
import {useAppDispatch, useAppSelector} from "../Store/Hooks";
import {useQueryClient} from "react-query";
import {
    getGetQuizApiInstanceIdGetQueryKey,
    getGetQuizQuestionApiQuizGetQueryKey
} from "../Client/default/default";
import {useLocation} from "react-router-dom";
import {setHideBackButton} from "../Store/Features/SettingsReducer";
import {GetQuizApiInstanceIdGetParams} from "../Client/Models";

export default function useWebSockets() {
    const dispatch = useAppDispatch();
    const settings = useAppSelector((state) => state.settings);
    const team = useAppSelector((state) => state.team.team);

    const location = useLocation();
    const queryClient = useQueryClient();

    useEffect(() => {
        console.log(`UUID: ${settings.uuid}`)
        console.log(`connecting to ws: ${process.env.REACT_APP_WS}`)

        console.log(`auth: ${settings.uuid}`)
        const socket = io(`${process.env.REACT_APP_WS}`, {
            path: process.env.REACT_APP_WS_PATH,
            auth: {
                "uuid": settings.uuid
            }
        });

        socket.on('connect', () => {
            console.log('[SOCKET] connected')
            const engine = socket.io.engine;
            console.log('[SOCKET] Engine', engine.transport.name);

            engine.once("upgrade", () => {
                console.log('[SOCKET] Engine', engine.transport.name);
            });

            socket.emit('fetch', "data");
        });

        socket.on("disconnect", () => {
            console.log('[SOCKET] disconnected')
        });

        socket.on("full_refresh", async () => {
            console.log('[SOCKET] full refresh requested')
            await queryClient.invalidateQueries();
        });

        socket.on("refresh_question", async () => {
            console.log('[SOCKET] question refresh requested')
            await queryClient.invalidateQueries(getGetQuizQuestionApiQuizGetQueryKey());
            await queryClient.invalidateQueries(getGetQuizApiInstanceIdGetQueryKey({} as GetQuizApiInstanceIdGetParams));
        });

        socket.on("ping", async () => {
            console.log('[SOCKET] ping received')
            if (location.pathname === "/quiz" && team._id !== "") {
                console.log('[SOCKET] sending pong')
                socket.emit("pong", team._id);
            }
        });

        socket.on("backbutton", async (data) => {
            console.log('[SOCKET] back button visibility change requested')

            if ('visible' in data) {
                let visible = data.visible;
                dispatch(setHideBackButton(!visible));
            }
        });

        return () => {
            socket.disconnect();
        }
    }, [team]);
}
