import React from "react";
import {
    Button,
    Divider, Modal,
    ModalBody,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    useDisclosure
} from "@chakra-ui/react";
import {useQueryClient} from "react-query";
import {DisplaySize, Quiz, QuizInstance} from "../../../../Client/Models";
import {useNavigate} from "react-router-dom";
import {
    createInstanceApiInstanceCreatePut,
    getGetInstancesApiInstanceGetQueryKey,
    removeInstanceApiInstanceDelete,
    renameInstanceApiInstancePatch,
    useGetInstancesApiInstanceGet, useGetQuizApiTemplatesGet,

} from "../../../../Client/default/default";
import Loading from "../../../Utils/Loading";
import {EmptyMessage} from "../../../Utils/EmptyMessage";
import CustomList from "../../../Utils/CustomList";

const QuizInstanceCreateButton = () => {
    const queryClient = useQueryClient();
    const {
        isOpen: isQuizInstanceModalOpen,
        onOpen: openQuizInstanceModal,
        onClose: closeQuizInstanceModal
    } = useDisclosure()

    const navigate = useNavigate();
    const {isLoading, isError, data: quizTemplates} = useGetQuizApiTemplatesGet();

    return (
        <>
            <Button variant='outline' onClick={openQuizInstanceModal}>
                Quiz aanmaken
            </Button>
            <Modal
                onClose={closeQuizInstanceModal}
                isOpen={isQuizInstanceModalOpen}
                closeOnOverlayClick={true}
                isCentered
                size='lg'
            >
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Quiz Selection</ModalHeader>
                    <ModalBody>
                        { isLoading && <Loading/> }
                        { !isLoading && quizTemplates && quizTemplates!.data.length <= 0 &&
                            <EmptyMessage text={"Er zijn nog geen quiz templates aangemaakt"}/>
                        }
                        { !isLoading && quizTemplates && quizTemplates!.data.length > 0 &&
                            <CustomList
                                elements={quizTemplates!.data}
                                nameGetter={
                                    (q : Quiz) => q.name
                                }
                                onClick={
                                    async (q : Quiz) => {
                                        closeQuizInstanceModal();
                                        createInstanceApiInstanceCreatePut({
                                            quizId: q._id!
                                        }).then(r => {
                                            queryClient.invalidateQueries(getGetInstancesApiInstanceGetQueryKey())
                                                .then(d => {
                                                    if (r.data && r.data._id) {
                                                        navigate('', {
                                                            state: {
                                                                size: DisplaySize.FULL_SCREEN,
                                                                quizId: r.data._id!,
                                                            }
                                                        });
                                                    }
                                                });
                                        })
                                    }
                                }
                            />
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='red' onClick={closeQuizInstanceModal}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

const QuizRunnerSelector = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const {isLoading, isError, data: instances} = useGetInstancesApiInstanceGet();

    if (isLoading) {
        return <Loading/>;
    }
    if (!instances) {
        return <EmptyMessage text={"Error fetching quiz instances"}/>
    }

    return (
        <Stack>
            {instances.data.length > 0 &&
                <CustomList
                    elements={instances!.data}
                    nameGetter={
                        (q: QuizInstance) => q.name
                    }
                    onRename={
                        (q: QuizInstance, name: string) => {
                            console.log(`Renaming quiz : ${q.name} to ${name}`);
                            renameInstanceApiInstancePatch({
                                id: q._id!,
                                name: name
                            }).then(r =>
                                queryClient.invalidateQueries(getGetInstancesApiInstanceGetQueryKey())
                            );
                        }
                    }
                    onDelete={
                        (q: QuizInstance) => {
                            removeInstanceApiInstanceDelete({
                                id: q._id!,
                            }).then(r =>
                                queryClient.invalidateQueries(getGetInstancesApiInstanceGetQueryKey())
                            );
                        }
                    }
                    onClick={
                        (q: QuizInstance) => {
                            navigate('', {
                                state: {
                                    size: DisplaySize.FULL_SCREEN,
                                    quizId: q._id!,
                                }
                            });
                        }
                    }
                />
            }
            {instances.data.length <= 0 &&
                (
                    <EmptyMessage text={"Er zijn nog geen quiz instances aangemaakt"}/>
                )
            }
            <Divider my={3}/>
            <QuizInstanceCreateButton/>
        </Stack>
    )
}

export {
    QuizRunnerSelector
}
