import {
    AlertDialog,
    AlertDialogBody, AlertDialogContent,
    AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay,
    Button,
    ButtonGroup, Center, Editable,
    EditableInput,
    HStack,
    IconButton,
    Input, Stack, useDisclosure,
    useEditableControls
} from "@chakra-ui/react";
import {MdCheck, MdClose} from "react-icons/md";
import {BsPencil, BsTrash} from "react-icons/bs";
import React, {useState} from "react";

type CustomListParams<T> = {
    elements: T[],
    nameGetter: (t: T) => string,
    onClick?: (t: T, index: number) => void,
    onRename?: (t: T, name: string, index: number) => void,
    onDelete?: (t: T, index: number) => void,
    customElements?: (t: T, index: number) => (React.ReactElement | null)
};

type CustomListElementParams<T> = {
    listParams: CustomListParams<T>
    element: T,
    index: number
};

function CustomListElement<T>(p: CustomListElementParams<T>) {
    const t = p.element;
    const [name, setName] = useState(p.listParams.nameGetter(t));

    const clickable = p.listParams.onClick !== undefined;
    const hasRename = p.listParams.onRename !== undefined;
    const hasDelete = p.listParams.onDelete !== undefined;
    const hasCustomElements = p.listParams.customElements !== undefined;

    function EditableControls() {
        const {
            isEditing,
            getSubmitButtonProps,
            getCancelButtonProps,
            getEditButtonProps,
        } = useEditableControls()

        return isEditing ? (
            <ButtonGroup justifyContent='center'>
                <IconButton icon={<MdCheck/>}
                            variant='outline'
                            fontSize='20px'
                            aria-label={"Confirm"}
                            {...getSubmitButtonProps()} />
                <IconButton icon={<MdClose/>} variant='outline'
                            fontSize='20px'
                            aria-label={"Cancel"}
                            {...getCancelButtonProps()} />
            </ButtonGroup>
        ) : (
            <HStack w={"100%"}>
                {clickable &&
                    <Button w={"100%"}
                            onClick={() => p.listParams.onClick!(t, p.index)}>
                        {p.listParams.nameGetter(t)}
                    </Button>
                }
                {!clickable &&
                    <Center w={"100%"}
                            h={"2.5rem"}
                            fontSize="md"
                            fontWeight="semibold"
                            color='black'
                            bg="quizycolor.medium"
                            borderRadius="md"
                    >
                        {p.listParams.nameGetter(t)}
                    </Center>
                }
                {hasRename &&
                    <IconButton icon={<BsPencil/>}
                                variant='outline'
                                fontSize='20px'
                                aria-label={"Edit"}
                                {...getEditButtonProps()} />
                }
            </HStack>
        )
    }

    const {isOpen, onOpen, onClose} = useDisclosure()
    const cancelRef = React.useRef(null)

    return (
        <HStack>
            <Editable w={"100%"}
                      defaultValue={p.listParams.nameGetter(t)}
                      value={name}
                      onSubmit={() => {
                          if (p.listParams.onRename) {
                              p.listParams.onRename(t, name, p.index)
                          }
                      }
                      }
                      onChange={(newValue) => setName(newValue)}
                      isPreviewFocusable={false}
            >
                <HStack>
                    <Input as={EditableInput} w={"100%"}/>
                    <EditableControls/>
                    {hasDelete &&
                        <>
                            <IconButton
                                variant='outline'
                                colorScheme='red'
                                aria-label='Remove Quiz'
                                fontSize='20px'
                                icon={<BsTrash/>}
                                onClick={onOpen}
                            />

                            <AlertDialog
                                isOpen={isOpen}
                                leastDestructiveRef={cancelRef}
                                onClose={onClose}
                            >
                                <AlertDialogOverlay>
                                    <AlertDialogContent>
                                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                            Are you sure?
                                        </AlertDialogHeader>

                                        <AlertDialogBody>
                                            Are you sure you want to delete?
                                            This action is not recoverable.
                                        </AlertDialogBody>

                                        <AlertDialogFooter>
                                            <Button ref={cancelRef} onClick={onClose}>
                                                Cancel
                                            </Button>
                                            <Button colorScheme='red' onClick={() => {
                                                p.listParams.onDelete!(t, p.index);
                                                onClose();
                                            }} ml={3}>
                                                Delete
                                            </Button>
                                        </AlertDialogFooter>
                                    </AlertDialogContent>
                                </AlertDialogOverlay>
                            </AlertDialog>
                        </>
                    }
                    {hasCustomElements &&
                        p.listParams.customElements!(t, p.index)
                    }
                </HStack>
            </Editable>
        </HStack>
    )
}

export default function CustomList<T>(params: CustomListParams<T>) {
    return (
        <Stack>
            {
                params.elements.map((t, i) => (
                    <CustomListElement
                        listParams={params}
                        element={t}
                        key={`customlist-${i}`}
                        index={i}/>
                ))
            }
        </Stack>
    )
}
