import {uploadDataUploadPost} from "../../../../../Client/default/default";

const insertToTextArea = (intsertString: string) => {
    const textarea = document.querySelector('textarea');
    if (!textarea) {
        return null;
    }

    let sentence = textarea.value;
    const len = sentence.length;
    const pos = textarea.selectionStart;
    const end = textarea.selectionEnd;

    const front = sentence.slice(0, pos);
    const back = sentence.slice(pos, len);

    sentence = front + intsertString + back;

    textarea.value = sentence;
    textarea.selectionEnd = end + intsertString.length;

    return sentence;
};

const onImagePasted = async (dataTransfer: DataTransfer, setMarkdown: (value: string | undefined) => void) => {
    const files: File[] = [];
    for (let index = 0; index < dataTransfer.items.length; index += 1) {
        const file = dataTransfer.files.item(index);

        if (file) {
            files.push(file);
        }
    }

    if (files.length <= 0) {
        return false;
    }

    let results = await Promise.all(
        files.map(async (file) => {
            const response : any = await uploadDataUploadPost({
                file: file
            });
            if (!('data' in response) || !('path' in response.data)) {
                console.log(`Upload failed:`);
                console.log(response);
                return false;
            }
            const url = `${process.env.REACT_APP_MINIO_HOST}/${response.data.path}`
            const insertedMarkdown = insertToTextArea(`![image](${url})`);
            if (!insertedMarkdown) {
                return false;
            }
            setMarkdown(insertedMarkdown);
            return true;
        }),
    );

    return results.every(r => r.valueOf())
};

export default onImagePasted;
