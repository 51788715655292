import React, {useCallback, useContext, useEffect, useState} from "react";
import {
    Box,
    Button,
    Center, chakra,
    Divider, Flex,
    Heading, HStack, Menu, MenuButton, MenuItem, MenuList, Stack, Table, TableContainer, Tbody, Text, Th, Thead, Tr
} from "@chakra-ui/react";
import {useLocation} from "react-router-dom";
import {AnimateMount} from "../../../Utils/Animation";
import {QuizRunnerSelector} from "./QuizRunnerSelector";
import {
    moveApiInstanceMovePatch, pingApiQuizPingPut,
    setActivationApiInstanceActivatePatch, setBackbuttonVisisbleApiInstanceBackbuttonPut,
    useGetQuizApiInstanceIdGet,
    useGetTeamsApiTeamsGet
} from "../../../../Client/default/default";
import Loading from "../../../Utils/Loading";
import {EmptyMessage} from "../../../Utils/EmptyMessage";
import {
    QuizInstance,
    QuizInstanceQuestionsItem,
    Team
} from "../../../../Client/Models";
import {useQueryClient} from "react-query";
import {discriminateQuestionInstance} from "../../../Utils/QuestionDiscriminator";
import {
    GTDQuestionController,
    QuestionInstanceState,
    StringAnswer,
    QuestionController, useAnswerPoints, useTeamPoints
} from "./QuizQuestionControllers";
import {QuizContext} from "./QuizContext";
import {Disable} from 'react-disable';
import {MdRefresh} from "react-icons/md";
import {IoMdEye, IoMdEyeOff} from "react-icons/io";
import {CgPushChevronLeft, CgPushChevronRight} from "react-icons/cg";
import {AnimatePresence, motion} from "framer-motion";
import {BounceLoader} from "react-spinners";
import {RiSignalTowerFill} from "react-icons/ri";
import {BsChevronDown} from "react-icons/bs";
import {HiLockClosed, HiLockOpen} from "react-icons/hi";
import {toast} from "react-toastify";
import {fastDialogSettings} from "../../../../ToastUtils";
import {themeGetter} from "../../../../Theme";
import {useAppSelector} from "../../../../Store/Hooks";

interface QuizQuestionControllerProps {
    instance: QuizInstanceQuestionsItem;
    teams: Team[];
    active: boolean;
    hideAnswers: boolean;
}

const QuizQuestionControllerDiscriminator = (props: QuizQuestionControllerProps) => {
    return discriminateQuestionInstance<React.ReactElement | null>(
        props.instance,
        {
            Blank: (q) => QuestionController(
                () => <QuestionInstanceState question={q} teams={props.teams} active={props.active}/>,
                (t) => (<Text>-</Text>),
                q,
                q.question.name,
                props.active,
                props.hideAnswers,
                props.teams),
            Open: (q) => QuestionController(
                () => <QuestionInstanceState question={q} teams={props.teams} active={props.active}/>,
                (t) => <StringAnswer
                    q={q}
                    t={t}
                    answers={q.answers}
                    active={props.active && !q.question.isShowAnswer}
                    submitted={q.submitted.includes(t._id!)}
                    canUseModal={true}
                />,
                q,
                q.question.name,
                props.active,
                props.hideAnswers,
                props.teams),
            MultipleChoice: (q) => QuestionController(
                () => <QuestionInstanceState question={q} teams={props.teams} active={props.active}/>,
                (t) => <StringAnswer
                    q={q}
                    t={t}
                    answers={q.answers}
                    active={props.active && !q.question.isShowAnswer}
                    submitted={q.submitted.includes(t._id!)}
                />,
                q,
                q.question.name,
                props.active,
                props.hideAnswers,
                props.teams),
            MultipleAnswer: (q) => QuestionController(
                () => <QuestionInstanceState question={q} teams={props.teams} active={props.active}/>,
                (t) => <StringAnswer
                    q={q}
                    t={t}
                    answers={q.answers}
                    active={props.active && !q.question.isShowAnswer}
                    submitted={q.submitted.includes(t._id!)}
                />,
                q,
                q.question.name,
                props.active,
                props.hideAnswers,
                props.teams),
            GuessTheDefinition: (q) => GTDQuestionController(q, props.teams, props.active, props.hideAnswers),
            default: (q) => null
        }
    )
}

interface QuizInstanceControllerProps {
    teams: Team[];
    pongTeams: string[];
    hideAnswers: boolean;
}

const ChakraDisable = chakra(Disable)

const QuizInstanceController = (props: QuizInstanceControllerProps) => {
    const quiz = useContext<QuizInstance | undefined>(QuizContext);

    let correctMap = useTeamPoints(props.teams, (quiz === undefined) ? [] : quiz.questions)

    if (!quiz) {
        return null;
    }

    const active = quiz!.active;

    return (
        <ChakraDisable disabled={!active} h={'100%'} overflowY={'hidden'}>
            <TableContainer fontSize='sm' overflowY="scroll" h={'100%'}>
                <Table>
                    <Thead
                        position="sticky"
                        zIndex={1}
                        top={-1}
                        bg={themeGetter().colors.quizycolor.verylight}>
                        <Tr>
                            <Th color={'black'} w={'15px'}>State</Th>
                            <Th color={'black'} w={'50px'}>Controls</Th>
                            <Th color={'black'} w={'50px'}>Name</Th>
                            <Th color={'black'}>Type</Th>
                            {!props.hideAnswers &&
                                <Th color={'black'}>Answer</Th>
                            }
                            {props.teams.map((t, i) =>
                                <Th key={`h-${i}`} color={'black'}>
                                    <HStack>
                                        <Text>
                                            {correctMap[t._id!] > 0 &&
                                                `${t.name} (${correctMap[t._id!]})`
                                            }
                                            {correctMap[t._id!] <= 0 &&
                                                t.name
                                            }
                                        </Text>
                                        {props.pongTeams.includes(t._id!) &&
                                            <AnimatePresence>
                                                <motion.div
                                                    initial={{opacity: 0}}
                                                    animate={{opacity: 1}}
                                                    exit={{opacity: 0}}
                                                    transition={{duration: 3}}
                                                >
                                                    <BounceLoader
                                                        color={themeGetter().colors.quizycolor.dark}
                                                        size={'20px'}
                                                        aria-label="Pong Spinner"
                                                        data-testid="pong"/>
                                                </motion.div>
                                            </AnimatePresence>
                                        }
                                    </HStack>
                                </Th>
                            )
                            }
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            quiz!.questions.map(q =>
                                <QuizQuestionControllerDiscriminator
                                    key={q._id}
                                    teams={props.teams}
                                    instance={q}
                                    active={active ? q._id === quiz!.activeQuestionId : false}
                                    hideAnswers={props.hideAnswers}
                                />
                            )
                        }
                    </Tbody>
                </Table>
            </TableContainer>
        </ChakraDisable>
    )
}

const QuizRunner = () => {
    const location = useLocation();
    const queryClient = useQueryClient();

    const [pongTeams, setPongTeams] = useState<string[]>([]);
    const [hideAnswers, setHideAnswers] = useState(false);
    const state = location.state;
    const quizId = state?.quizId;
    const {isLoading: isLoadingQuiz, isError: isErrorQuiz, data: quiz} = useGetQuizApiInstanceIdGet({
        id: quizId || ""
    }, {
        query: {
            enabled: !!quizId
        }
    });
    const {isLoading: isLoadingTeams, isError: isErrorTeams, data: teams} = useGetTeamsApiTeamsGet();

    const moveAnswer = useCallback((forward: boolean) => {
        moveApiInstanceMovePatch({
            forward: forward
        }).then(r => {
            queryClient.invalidateQueries()
        })
    }, [queryClient])

    if (!state || !quizId) {
        return (
            <AnimateMount customKey={'quiz-runner-selector'}>
                <QuizRunnerSelector/>
            </AnimateMount>
        )
    }

    if (isLoadingQuiz || isLoadingTeams) {
        return <Loading/>;
    }
    if (!quiz || !teams) {
        return <EmptyMessage text={"Error fetching quiz"}/>
    }

    const quizInstance: QuizInstance = quiz.data;
    const teamsData: Team[] = teams.data;
    const id = quizInstance._id!;

    return (
        <AnimateMount customKey={id}>
            <Stack h={'100%'}>
                <Center>
                    <Button
                        colorScheme='green'
                        isDisabled={quizInstance.active}
                        onClick={() => {
                            setActivationApiInstanceActivatePatch({
                                quizId: id,
                                activate: true
                            }).then(r =>
                                queryClient.invalidateQueries()
                            );
                        }
                        }
                        mr={3}>
                        Activate
                    </Button>
                    <Button
                        colorScheme='red'
                        onClick={() => {
                            setActivationApiInstanceActivatePatch({
                                quizId: id,
                                activate: false
                            }).then(r =>
                                queryClient.invalidateQueries()
                            );
                        }
                        }
                        isDisabled={!quizInstance.active}>
                        Disable
                    </Button>
                </Center>
                <Center>
                    <Menu>
                        <MenuButton
                            as={Button}
                            rightIcon={<BsChevronDown/>}
                            mr={3}
                            isDisabled={!quizInstance.active}>
                            Acties
                        </MenuButton>
                        <MenuList zIndex={10}>
                            <MenuItem
                                isDisabled={!quizInstance.active}
                                icon={<MdRefresh/>}
                                onClick={() => queryClient.invalidateQueries()}>
                                Refresh
                            </MenuItem>
                            <MenuItem
                                isDisabled={!quizInstance.active}
                                icon={<RiSignalTowerFill/>}
                                onClick={async () => {
                                    await pingApiQuizPingPut().then(r => {
                                        let teams = r.data;
                                        setPongTeams(teams)

                                        if (r.data.length <= 0) {
                                            toast.warning(`Er zijn geen teams online`, {
                                                toastId: 'toasts.ping-result',
                                                ...fastDialogSettings
                                            });
                                        } else {
                                            const message = r.data.length > 1 ?
                                                `Er zijn ${r.data.length} teams online` :
                                                `Er is ${r.data.length} team online`
                                            toast.success(message, {
                                                toastId: 'toasts.ping-result',
                                                ...fastDialogSettings
                                            });
                                        }


                                        return new Promise(r => setTimeout(r, 5000));
                                    }).then(r => {
                                        setPongTeams([])
                                    });
                                }
                                }>
                                Ping
                            </MenuItem>
                            <MenuItem
                                isDisabled={!quizInstance.active}
                                icon={hideAnswers ? <IoMdEyeOff/> : <IoMdEye/>}
                                onClick={() => setHideAnswers(!hideAnswers)}>
                                {
                                    hideAnswers ? "Toon antwoorden" : "Verberg antwoorden"
                                }
                            </MenuItem>
                            <MenuItem
                                isDisabled={!quizInstance.active}
                                icon={<HiLockClosed/>}
                                onClick={async () => {
                                    await setBackbuttonVisisbleApiInstanceBackbuttonPut({
                                        visible: false
                                    });
                                }}>
                                Verberg terug knop
                            </MenuItem>
                            <MenuItem
                                isDisabled={!quizInstance.active}
                                icon={<HiLockOpen/>}
                                onClick={async () => {
                                    await setBackbuttonVisisbleApiInstanceBackbuttonPut({
                                        visible: true
                                    });
                                }}>
                                Toon terug knop
                            </MenuItem>
                        </MenuList>
                    </Menu>
                    <Button
                        isDisabled={!quizInstance.active}
                        leftIcon={<CgPushChevronLeft/>}
                        onClick={() => moveAnswer(false)}
                        mr={3}>
                        Vorige
                    </Button>
                    <Button
                        isDisabled={!quizInstance.active}
                        rightIcon={<CgPushChevronRight/>}
                        onClick={() => moveAnswer(true)}>
                        Volgende
                    </Button>
                </Center>
                <QuizContext.Provider value={quizInstance}>
                    <QuizInstanceController teams={teamsData} hideAnswers={hideAnswers} pongTeams={pongTeams}/>
                </QuizContext.Provider>
            </Stack>
        </AnimateMount>
    )
}

export {
    QuizRunner
}
