import {
    BlankQuestion, MultipleAnswerQuestion,
    QuizQuestionsItem
} from "../../../../../Client/Models";
import React from "react";
import {Box, Button, Center, Divider, IconButton, Stack} from "@chakra-ui/react";
import {BaseQuestionEditor} from "./BaseQuestionEditor";
import CustomList from "../../../../Utils/CustomList";
import {MdCheck} from "react-icons/md";

const MultipleAnswerQuestionEditor = (props: {
    question: MultipleAnswerQuestion,
    onSetQuestion: (question: QuizQuestionsItem) => void
    children?: React.ReactNode
}) => {
    return (
        <BaseQuestionEditor
            question={props.question as BlankQuestion}
            onSetQuestion={props.onSetQuestion}
            hasShowAnswer={true}>
            <Stack>
                <Center>
                    <Box w={'75%'}>
                        <CustomList
                            elements={props.question.options}
                            nameGetter={
                                (o: string) => o
                            }
                            onRename={
                                (o: string, name, index) => {
                                    let answers = props.question.answers;
                                    const options = props.question.options;
                                    const optionName = options[index];

                                    const correct = answers.includes(optionName);

                                    options[index] = name;
                                    props.onSetQuestion({
                                        ...props.question,
                                        options: options
                                    })
                                    if (correct) {
                                        let new_answers = answers.filter(a => a != optionName);
                                        new_answers.push(name)
                                        props.onSetQuestion({
                                            ...props.question,
                                            answers: new_answers
                                        })
                                    }
                                }
                            }
                            onDelete={
                                (o: string, index) => {
                                    let options = props.question.options;
                                    options.splice(index, 1)
                                    props.onSetQuestion({
                                        ...props.question,
                                        options: options
                                    })
                                }
                            }
                            customElements={
                                (o: string, index) => {
                                    let answers = props.question.answers;
                                    const options = props.question.options;
                                    const optionName = options[index];

                                    const correct = answers.includes(optionName);

                                    return (
                                        <IconButton
                                            variant={correct ? 'solid' : 'outline'}
                                            aria-label='Set Correct'
                                            fontSize='20px'
                                            icon={<MdCheck/>}
                                            onClick={() => {
                                                if (correct) {
                                                    answers.splice(index, 1)
                                                    props.onSetQuestion({
                                                        ...props.question,
                                                        answers: [...answers]
                                                    })
                                                } else {
                                                    props.onSetQuestion({
                                                        ...props.question,
                                                        answers: [...answers, optionName]
                                                    })
                                                }

                                            }
                                            }
                                        />
                                    )
                                }
                            }
                        />
                    </Box>
                </Center>
                <Divider my={3}/>
                <Button
                    variant='outline'
                    onClick={() => {
                        let name = 'Optie ' + (props.question.options.length + 1)

                        props.onSetQuestion({
                            ...props.question,
                            options: [...props.question.options, name]
                        })
                    }
                    }
                >
                    Optie toevoegen
                </Button>
            </Stack>

        </BaseQuestionEditor>
    )
}

export {
    MultipleAnswerQuestionEditor
}
