import {
    Button,
    Center,
    Divider,
    Heading, HStack, IconButton, Modal,
    ModalBody, ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, Select, Stack,
    Text, useDisclosure, VStack
} from "@chakra-ui/react";
import {useLocation} from "react-router-dom";
import {
    addQuestionApiTemplatesQuestionsPut, duplicateQuestionApiTemplatesQuestionsDuplicatePut,
    getGetQuizApiTemplatesIdGetQueryKey,
    modifyQuestionApiTemplatesQuestionsPatch,
    moveQuestionApiTemplatesQuestionsMovePost,
    removeQuestionApiTemplatesQuestionsDelete,
    useGetQuizApiTemplatesIdGet
} from "../../../../Client/default/default";
import {
    DragDropContext,
    Droppable,
    Draggable,
    DraggableStateSnapshot,
    DraggableProvided,
    DroppableProvided, DroppableStateSnapshot
} from "react-beautiful-dnd";
import React, {useState} from "react";
import {
    QuestionType,
    QuizQuestionsItem
} from "../../../../Client/Models";
import {BaseQuestionEditor} from "./QuestionEditors/BaseQuestionEditor";
import {useMutation, useQueryClient} from "react-query";
import {BsPencil, BsTrash} from "react-icons/bs";
import {OpenQuestionEditor} from "./QuestionEditors/OpenQuestionEditor";
import {QuizBuilderSelector} from "./QuizBuilderSelector";
import {EmptyMessage} from "../../../Utils/EmptyMessage";
import Loading from "../../../Utils/Loading";
import {AnimateMount} from "../../../Utils/Animation";
import {MdContentCopy} from "react-icons/md";
import {discriminateQuestion} from "../../../Utils/QuestionDiscriminator";
import {MultipleChoiceQuestionEditor} from "./QuestionEditors/MultipleChoiceQuestionEditor";
import {MultipleAnswerQuestionEditor} from "./QuestionEditors/MultipleAnswerQuestionEditor";
import {GuessTheDefinitionQuestionEditor} from "./QuestionEditors/GuessTheDefinitionQuestionEditor";
import {QuestionPreviewModal} from "../../../Quiz/QuestionPreviewModal";
import {IoMdEye} from "react-icons/io";
import {QuestionPopulator} from "./DefaultQuestionBuilder";

const QuestionListItem = (props: {
    question: QuizQuestionsItem,
    setEditQuestion: (question: QuizQuestionsItem) => void,
    openQuestionEditor: () => void,
    quizId: string
}) => {
    const queryClient = useQueryClient();

    const removeQuestion = () => {
        removeQuestionApiTemplatesQuestionsDelete({
            quizId: props.quizId,
            questionId: props.question._id!
        }).then(r =>
            queryClient.invalidateQueries(getGetQuizApiTemplatesIdGetQueryKey({
                id: props.quizId
            })))
    }

    const duplicateQuestion = () => {
        duplicateQuestionApiTemplatesQuestionsDuplicatePut({
            quizId: props.quizId,
            questionId: props.question._id!
        }).then(r =>
            queryClient.invalidateQueries(getGetQuizApiTemplatesIdGetQueryKey({
                id: props.quizId
            })))
    }

    return (
        <Center w={"100%"} my={2}>
            <HStack
                w={"100%"}
                bg={props.question.isShowAnswer ? "quizycolor.medium" : "quizycolor.mediumlight"}
                borderRadius="md"
            >
                <Text w={"10%"}>
                    {
                        props.question.isShowAnswer ? "(A)" : "(Q)"
                    }
                </Text>
                <Center w={"100%"}>
                    {props.question.name}
                </Center>
            </HStack>
            <IconButton
                ml={2}
                icon={<BsPencil/>}
                size={'xs'}
                variant='outline'
                fontSize={'16px'}
                aria-label={"Edit"}
                onClick={() => {
                    props.setEditQuestion(props.question);
                    props.openQuestionEditor();
                }
                }
            />
            <IconButton
                ml={2}
                icon={<MdContentCopy/>}
                size={'xs'}
                variant='outline'
                fontSize={'16px'}
                aria-label={"Duplicate"}
                onClick={duplicateQuestion}
            />
            <IconButton
                ml={2}
                icon={<BsTrash/>}
                size={'xs'}
                variant='outline'
                colorScheme='red'
                fontSize={'16px'}
                aria-label={"Delete"}
                onClick={removeQuestion}
            />
        </Center>
    )
}

const QuestionTypeEditor = (props: {
    question: Partial<QuizQuestionsItem>,
    onSetQuestion: (question: QuizQuestionsItem) => void
}) => {
    return discriminateQuestion<React.ReactElement | null>(
        props.question,
        {
            Blank: (q) =>
                <BaseQuestionEditor
                    question={q}
                    onSetQuestion={props.onSetQuestion}
                    hasShowAnswer={false}/>,
            Open: (q) =>
                <OpenQuestionEditor question={q} onSetQuestion={props.onSetQuestion}/>,
            MultipleChoice: (q) =>
                <MultipleChoiceQuestionEditor question={q} onSetQuestion={props.onSetQuestion}/>,
            MultipleAnswer: (q) =>
                <MultipleAnswerQuestionEditor question={q} onSetQuestion={props.onSetQuestion}/>,
            GuessTheDefinition: (q) =>
                <GuessTheDefinitionQuestionEditor question={q} onSetQuestion={props.onSetQuestion}/>,
            default: () => null
        }
    );
}

const QuestionEditor = (props: {
    question: QuizQuestionsItem,
    onSetQuestion: (question: QuizQuestionsItem) => void
}) => {
    return (
        <VStack align={"left"}>
            <Select placeholder='Select option'
                    value={props.question.type}
                    onChange={(e: any) => props.onSetQuestion({...props.question, type: e.target.value})}>
                <option value={QuestionType.BLANK}>Blank Question</option>
                <option value={QuestionType.OPEN}>Open Question</option>
                <option value={QuestionType.MULTIPLE_CHOICE}>Multiple Choice Question</option>
                <option value={QuestionType.MULTIPLE_ANSWER}>Multiple Answer Question</option>
                <option value={QuestionType.GUESS_THE_DEFINITION}>Guess The Definition</option>
            </Select>
            {props.question.type && <Divider/>}
            <QuestionTypeEditor question={props.question} onSetQuestion={props.onSetQuestion}/>
        </VStack>
    )
}

const QuestionEditorModal = (props: {
    quizId: string,
    isOpen: boolean,
    onClose: () => void,
    question: QuizQuestionsItem,
    onSetQuestion: (question: QuizQuestionsItem) => void
}) => {
    const queryClient = useQueryClient();

    const updateQuestion = () => {
        if ('type' in props.question) {
            if (!props.question.type) {
                props.onClose();
                return;
            }
        } else {
            props.onClose();
            return;
        }
        if ('_id' in props.question) {
            console.log("Modifying question...")
            modifyQuestionApiTemplatesQuestionsPatch(
                props.question,
                {
                    quizId: props.quizId,
                    questionId: props.question._id!,
                }).then(r =>
                queryClient.invalidateQueries(getGetQuizApiTemplatesIdGetQueryKey({
                    id: props.quizId
                })))
        } else {
            console.log("Adding question...")
            addQuestionApiTemplatesQuestionsPut(
                props.question,
                {
                    quizId: props.quizId
                }).then(r =>
                queryClient.invalidateQueries(getGetQuizApiTemplatesIdGetQueryKey({
                    id: props.quizId
                })))
        }


        props.onClose();
    }

    const {isOpen: isQuestionPreviewOpen, onOpen: openQuestionPreview, onClose: closeQuestionPreview} = useDisclosure()

    const question = QuestionPopulator(props.question);

    return (
        <>
            <QuestionPreviewModal question={question} onClose={closeQuestionPreview}
                                  isOpen={isQuestionPreviewOpen}/>
            <Modal
                onClose={props.onClose}
                isOpen={props.isOpen}
                closeOnOverlayClick={false}
                isCentered
                size='2xl'
            >
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Question Editor</ModalHeader>
                    <ModalBody>
                        <QuestionEditor question={question} onSetQuestion={props.onSetQuestion}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button leftIcon={<IoMdEye/>} onClick={openQuestionPreview} mx={3}>Preview</Button>
                        <Button colorScheme='red' onClick={props.onClose}>Cancel</Button>
                        <Button onClick={updateQuestion} mx={3}>Ok</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

const QuizBuilder = () => {
    const queryClient = useQueryClient();
    const {isOpen, onOpen: openQuestionEditor, onClose} = useDisclosure()
    const [editQuestion, setEditQuestion] = useState({} as QuizQuestionsItem)
    const location = useLocation();

    const state = location.state;
    const quizId = state?.quizId;
    const {isLoading, isError, data: quiz} = useGetQuizApiTemplatesIdGet({
        id: quizId || ""
    }, {
        query: {
            enabled: !!quizId
        }
    });

    const reorder = (list: QuizQuestionsItem[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const quizDragMutation = useMutation({
        mutationFn: async (data: { questionId: string, from: number, to: number }) => {
            await moveQuestionApiTemplatesQuestionsMovePost({
                quizId: quizId!,
                questionId: data.questionId,
                position: data.to,
            })
        },
        onMutate: async (data: { questionId: string, from: number, to: number }) => {
            const queryKey = getGetQuizApiTemplatesIdGetQueryKey({
                id: quizId!
            });
            // Stop the queries that may affect this operation
            await queryClient.cancelQueries(queryKey);
            // Get a snapshot of current data
            const queryDataSnapshot: any = queryClient.getQueryData(queryKey)!;

            // Optimistic update
            queryClient.setQueryData<any>(queryKey, (oldQueryData: any) => {
                if (!oldQueryData || !oldQueryData.data) {
                    return undefined;
                }

                oldQueryData.data.questions = reorder(
                    oldQueryData.data.questions,
                    data.from,
                    data.to
                );

                return oldQueryData;
            });

            // Return a snapshot for rollback
            return {
                queryDataSnapshot
            }
        },

        onError: (error, variables, context) => {
            if (context) {
                // Rollback
                queryClient.setQueryData(getGetQuizApiTemplatesIdGetQueryKey({
                    id: quizId!
                }), context.queryDataSnapshot)
            }
        },

        onSettled() {
            queryClient.invalidateQueries(getGetQuizApiTemplatesIdGetQueryKey({
                id: quizId!
            }))
        }
    })

    if (!state || !quizId) {
        return (
            <AnimateMount customKey={'quiz-builder-selector'}>
                <QuizBuilderSelector/>
            </AnimateMount>
        )
    }

    if (isLoading) {
        return <Loading/>;
    }
    if (!quiz) {
        return <EmptyMessage text={"Error fetching quiz"}/>
    }

    const quizIdSafe: string = quizId!

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }

        if (result.source.index === result.destination.index) {
            console.log("ignore");
            return;
        }

        const movedQuestionId = quiz.data.questions[result.source.index]!._id!;
        quizDragMutation.mutate({
            questionId: movedQuestionId,
            from: result.source.index,
            to: result.destination.index
        });
    }

    return (
        <AnimateMount customKey={quizIdSafe}>
            <Stack>
                <Heading as='h3' size='lg' mb={5}>
                    {quiz.data.name}
                </Heading>
                <Divider/>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable
                        droppableId="droppable"
                        renderClone={(provided, snapshot, rubric) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                <QuestionListItem
                                    question={quiz.data.questions[rubric.source.index]}
                                    setEditQuestion={setEditQuestion}
                                    openQuestionEditor={openQuestionEditor}
                                    quizId={quizIdSafe}
                                />
                            </div>
                        )}>
                        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {quiz.data.questions.map((question: QuizQuestionsItem, index) => (
                                    <Draggable key={question._id} draggableId={question._id!} index={index}>
                                        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <QuestionListItem
                                                    question={question}
                                                    setEditQuestion={setEditQuestion}
                                                    openQuestionEditor={openQuestionEditor}
                                                    quizId={quizIdSafe}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    <Button variant='outline'
                            w={"100%"}
                            onClick={() => {
                                setEditQuestion({} as QuizQuestionsItem)
                                openQuestionEditor();
                            }
                            }>
                        Vraag toevoegen
                    </Button>
                </DragDropContext>
                {/* Question editor */}
                <QuestionEditorModal
                    quizId={quizIdSafe}
                    isOpen={isOpen}
                    onClose={onClose}
                    question={editQuestion}
                    onSetQuestion={setEditQuestion}/>
            </Stack>
        </AnimateMount>
    )
}

export {
    QuizBuilder
}
