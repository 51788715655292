import {
    Button,
    ButtonGroup,
    Center,
    Checkbox,
    Editable,
    EditableInput,
    EditablePreview,
    Flex,
    HStack,
    Icon,
    IconButton,
    Input,
    Menu,
    MenuButton, MenuItem,
    MenuList,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper,
    Td,
    Text,
    Tr,
    useDisclosure,
    useEditableControls,
    VStack
} from "@chakra-ui/react";
import {BsPencil, BsPlay} from "react-icons/bs";
import {BeatLoader, BounceLoader, PuffLoader} from "react-spinners";
import React, {useContext, useEffect, useState} from "react";
import {
    BlankQuestionInstance,
    Team,
    QuizInstance,
    StringQuestionInstanceAnswers,
    QuizInstanceQuestionsItem,
    GTDQuestionInstance,
    GTDQuestionStage,
    OpenQuestion, MultipleChoiceQuestion, MultipleAnswerQuestion
} from "../../../../Client/Models";
import {
    activateGtdQuestionApiInstanceGtdQuestionPatch,
    activateQuestionApiInstanceQuestionPatch,
    giveGtdDefinitionApiQuizGtdDefinitionPut, refreshAllClientsApiRefreshAllPut,
    skipGtdDefinitionApiInstanceGtdSkipteamPatch
} from "../../../../Client/default/default";
import {QuizContext} from "./QuizContext";
import {useQueryClient} from "react-query";
import {discriminateQuestionInstance} from "../../../Utils/QuestionDiscriminator";
import {MdCheck, MdClear, MdClose, MdLock} from "react-icons/md";
import ShowMoreText from "react-show-more-text";
import {themeGetter} from "../../../../Theme";
import {QuestionInstancePreviewModal} from "../../../Quiz/QuestionPreviewModal";
import {IoMdEye} from "react-icons/io";
import {diceCoefficient} from "dice-coefficient";
import {CgDetailsMore} from "react-icons/cg";
import {resetCorrectness, setCorrect, setIncorrect, setValue} from "../../../../Store/Features/CorrectnessReducer";
import {useAppDispatch, useAppSelector} from "../../../../Store/Hooks";
import {GrClearOption} from "react-icons/gr";
import {AiOutlineClear} from "react-icons/ai";
import {FaCheck} from "react-icons/fa6";
import {IoCheckmark, IoCheckmarkDone} from "react-icons/io5";

const ActiveQuestionState = () => {
    return (
        <BeatLoader
            color={themeGetter().colors.quizycolor.dark}
            size={'5px'}
            aria-label="Loading Spinner"
            data-testid="loader"
        />
    )
}

const StringQuestionInstanceState = (props: { submitted: string[], teams: Team[], active: boolean }) => {
    let allSubmitted = true;
    for (const team of props.teams) {
        if (!(props.submitted.includes(team._id!))) {
            allSubmitted = false;
            break;
        }
    }

    if (allSubmitted) {
        return (
            <Icon
                aria-label='Complete'
                fontSize='20px'
                as={MdCheck}/>
        )
    } else {
        if (props.active) {
            return (
                <ActiveQuestionState/>
            )
        } else {
            return null;
        }
    }
}

const GTDInstanceState = (props: {
    question: GTDQuestionInstance,
    teams: Team[],
    active: boolean,
    state: GTDQuestionStage
}) => {
    let active = props.active && props.state === props.question.stage;

    let searchDict = props.state === GTDQuestionStage.COLLECTING_DEFINITIONS ?
        props.question.definitions : props.question.answers;

    let allReceived = true;
    for (const team of props.teams) {
        if (!(team._id! in searchDict)) {
            allReceived = false;
            break;
        }
    }

    let allSubmitted = true;
    for (const team of props.teams) {
        if (!(props.question.submitted.includes(team._id!))) {
            allSubmitted = false;
            break;
        }
    }

    if (allReceived && allSubmitted) {
        return (
            <Icon
                aria-label='Complete'
                fontSize='20px'
                as={MdCheck}/>
        )
    } else {
        if (active) {
            return (
                <ActiveQuestionState/>
            )
        } else {
            return null;
        }
    }
}

const QuestionInstanceState = (props: { question: QuizInstanceQuestionsItem, teams: Team[], active: boolean }) => {
    return discriminateQuestionInstance<React.ReactElement | null>(
        props.question,
        {
            Blank: q => props.active ? <ActiveQuestionState/> : null,
            Open: q => <StringQuestionInstanceState
                submitted={q.submitted}
                teams={props.teams}
                active={props.active}/>,
            MultipleAnswer: q => <StringQuestionInstanceState
                submitted={q.submitted}
                teams={props.teams}
                active={props.active}/>,
            MultipleChoice: q => <StringQuestionInstanceState
                submitted={q.submitted}
                teams={props.teams}
                active={props.active}/>,
            GuessTheDefinition: q => <StringQuestionInstanceState
                submitted={q.submitted}
                teams={props.teams}
                active={props.active}/>,
            default: q => null
        }
    )
}

const BlankQuestionController = (instance: BlankQuestionInstance, teams: Team[], active: boolean) => {
    const queryClient = useQueryClient();
    const quiz = useContext<QuizInstance | undefined>(QuizContext);
    if (!quiz) {
        return null;
    }

    return (
        <Tr>
            <Td>
                <QuestionInstanceState question={instance} teams={teams} active={active}/>
            </Td>
            <Td>
                <Center>
                    <IconButton
                        ml={2}
                        icon={<BsPlay/>}
                        size={'xs'}
                        fontSize={'16px'}
                        aria-label={"Activate"}
                        onClick={() => {
                            activateQuestionApiInstanceQuestionPatch({
                                quizId: quiz!._id!,
                                questionId: instance._id!
                            }).then(r => {
                                queryClient.invalidateQueries();
                            })
                        }}
                        mr={5}
                    />
                </Center>
            </Td>
            <Td>
                <Text noOfLines={1}>
                    {
                        instance.question.name
                    }
                </Text>
            </Td>
            {
                teams.map((t, i) =>
                    <Td key={`t-${instance._id}-${i}`}>
                        -
                    </Td>
                )
            }
        </Tr>
    )
}

function useAnswerPoints(team: Team, question: QuizInstanceQuestionsItem) {
    const correctness = useAppSelector((state) => state.correctness);

    if (team._id! in correctness && question._id! in correctness[team._id!]) {
        return correctness[team._id!][question._id!];
    }

    return IsAnswerCorrect(team, question) === true ? 1 : 0;
}

function useTeamPoints(teams: Team[], questions: QuizInstanceQuestionsItem[]) {
    const correctness = useAppSelector((state) => state.correctness);
    const map = {}

    for (let i = 0; i < teams.length; i++) {
        const t = teams[i];

        let points: number[] = [];
        for (let j = 0; j < questions.length; j++) {
            const q = questions[j];
            if (t._id! in correctness && q._id! in correctness[t._id!]) {
                points.push(correctness[t._id!][q._id!]);
            } else {
                points.push(IsAnswerCorrect(t, q) ? 1 : 0);
            }
        }

        map[t._id!] = points.reduce((acc, val) => acc + val, 0);
    }

    return map;
}

const StringAnswer = (props: {
    t: Team,
    q: QuizInstanceQuestionsItem,
    answers: StringQuestionInstanceAnswers,
    active: boolean,
    submitted: boolean,
    canUseModal?: boolean
}) => {
    let useModal: boolean = false
    if (props.canUseModal !== undefined) {
        useModal = props.canUseModal!
    }

    let hasAnswer = props.t._id! in props.answers
        && props.answers[props.t._id!].length > 0
        && props.answers[props.t._id!][0] !== "";

    const {isOpen, onOpen, onClose} = useDisclosure()
    const realPoints: number = useAnswerPoints(props.t, props.q);
    const dispatch = useAppDispatch();

    const correctness = useAppSelector((state) => state.correctness);
    const isForced = props.t._id! in correctness && props.q._id! in correctness[props.t._id!];

    if (hasAnswer) {
        const teamAnswers = props.answers[props.t._id!].sort();
        return (
            <>
                <HStack>
                    <VStack>
                        {props.submitted && props.active &&
                            <Icon
                                as={MdLock}
                            />
                        }
                        {!props.submitted && props.active &&
                            <PuffLoader
                                color={themeGetter().colors.quizycolor.dark}
                                size={'10px'}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        }
                        {
                            realPoints > 0 &&
                            <HStack display={'grid'} gridTemplateColumns={'1fr'}>
                                {
                                    !isForced &&
                                    <Icon
                                        gridRow={1}
                                        gridColumn={1}
                                        as={IoCheckmark}
                                    />
                                }
                                {
                                    isForced &&
                                    <Icon
                                        gridRow={1}
                                        gridColumn={1}
                                        as={IoCheckmarkDone}
                                    />
                                }
                                <Text fontSize={'9px'}
                                      paddingLeft={1}
                                      position={'relative'}
                                      bottom={1.5}
                                      right={0.5}
                                      gridRow={1}
                                      gridColumn={1}>
                                    {realPoints}
                                </Text>
                            </HStack>
                        }
                    </VStack>
                    <Menu isLazy>
                        <MenuButton>
                            <ShowMoreText
                                lines={3}
                                more="Meer"
                                less="Minder"
                                expandByClick={!useModal}
                                onClick={(e: boolean) => {
                                    if (useModal) {
                                        onOpen();
                                    }
                                }
                                }
                                width={150}
                                keepNewLines={true}
                            >
                                {
                                    teamAnswers.join('\n')
                                }
                            </ShowMoreText>
                        </MenuButton>
                        <MenuList>
                            <MenuItem icon={<CgDetailsMore/>} onClick={onOpen}>Show full text</MenuItem>
                            <MenuItem
                                closeOnSelect={false}
                                icon={<AiOutlineClear/>}
                                isDisabled={!isForced}
                                onClick={() => {
                                    dispatch(resetCorrectness({team: props.t._id!, question: props.q._id!}));
                                }}>
                                Clear forced points
                            </MenuItem>
                            <MenuItem closeOnSelect={false}>
                                <NumberInput
                                    min={0}
                                    value={realPoints}
                                    onChange={(valueString) => {
                                        const v = parseInt(valueString);
                                        dispatch(setValue({team: props.t._id!, question: props.q._id!, value: v}));
                                    }}>
                                    <NumberInputField/>
                                    <NumberInputStepper>
                                        <NumberIncrementStepper/>
                                        <NumberDecrementStepper/>
                                    </NumberInputStepper>
                                </NumberInput>
                            </MenuItem>
                        </MenuList>
                    </Menu>

                </HStack>
                <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
                    <ModalOverlay/>
                    <ModalContent>
                        <ModalHeader>{`Antwoord: ${props.t.name}`}</ModalHeader>
                        <ModalCloseButton/>
                        <ModalBody>
                            {
                                teamAnswers.join('\n').split('\n').map((e, i) =>
                                    <Text key={i}>{e}</Text>
                                )
                            }
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme='blue' mr={3} onClick={onClose}>
                                Close
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
        )
    } else {
        if (props.active) {
            return (
                <PuffLoader
                    color={themeGetter().colors.quizycolor.dark}
                    size={'15px'}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            )
        } else {
            return null;
        }
    }
}

const IsAnswerCorrect = (t: Team, q: QuizInstanceQuestionsItem) => {
    const answer = discriminateQuestionInstance(
        q,
        {
            Blank: (q) => "",
            Open: (q) => (q.question as OpenQuestion).answer,
            MultipleAnswer: (q) => (q.question as MultipleAnswerQuestion).answers.sort().join('\n'),
            MultipleChoice: (q) => (q.question as MultipleChoiceQuestion).answer,
            GuessTheDefinition: (q) => q.question.definition,
            default: (q) => ""
        }
    );

    const teamAnswer = discriminateQuestionInstance(
        q,
        {
            Blank: (q) => null,
            Open: (q) => t._id! in q.answers ? q.answers[t._id!].sort().join('\n') : null,
            MultipleAnswer: (q) => t._id! in q.answers ? q.answers[t._id!].sort().join('\n') : null,
            MultipleChoice: (q) => t._id! in q.answers ? q.answers[t._id!].sort().join('\n') : null,
            GuessTheDefinition: (q) => t._id! in q.answers ? q.answers[t._id!].toLowerCase() : null,
            default: (q) => null
        }
    );

    if (teamAnswer === null) {
        return null;
    } else {
        const dc = diceCoefficient(answer, teamAnswer);
        return discriminateQuestionInstance(
            q,
            {
                Blank: (q) => null,
                Open: (q) => dc > 0.7,
                MultipleAnswer: (q) => answer.toLowerCase() == teamAnswer.toLowerCase(),
                MultipleChoice: (q) => answer.toLowerCase() == teamAnswer.toLowerCase(),
                GuessTheDefinition: (q) => answer.toLowerCase() == teamAnswer.toLowerCase(),
                default: (q) => null
            }
        );
    }
}

const QuestionController = (
    stateElement: () => React.ReactElement | null,
    answerElement: (t: Team) => React.ReactElement | null,
    question: QuizInstanceQuestionsItem,
    name: string,
    active: boolean,
    hideAnswers: boolean,
    teams: Team[],
    onActivateQuestion?: () => void,
    type?: string,
    hasPreview: boolean = true
) => {
    const {isOpen: isQuestionPreviewOpen, onOpen: openQuestionPreview, onClose: closeQuestionPreview} = useDisclosure()
    const queryClient = useQueryClient();
    const quiz = useContext<QuizInstance | undefined>(QuizContext);
    if (!quiz) {
        return null;
    }

    const isShowAnswer = question.question.isShowAnswer;
    const questionId = question._id!;
    const answer = discriminateQuestionInstance(
        question,
        {
            Blank: (q) => "",
            Open: (q) => (q.question as OpenQuestion).answer,
            MultipleAnswer: (q) => (q.question as MultipleAnswerQuestion).answers.sort().join('\n'),
            MultipleChoice: (q) => (q.question as MultipleChoiceQuestion).answer,
            GuessTheDefinition: (q) => q.question.definition,
            default: (q) => ""
        }
    );

    return (
        <Tr css={{
            background: active ? themeGetter().colors.quizycolor.mediumlight : ''
        }}>
            <QuestionInstancePreviewModal
                instance={question}
                onClose={closeQuestionPreview}
                isOpen={isQuestionPreviewOpen}/>
            <Td>
                {
                    stateElement()
                }
            </Td>
            <Td>
                <HStack>
                    <IconButton
                        icon={<BsPlay/>}
                        size={'xs'}
                        fontSize={'16px'}
                        aria-label={"Activate"}
                        onClick={() => {
                            if (onActivateQuestion) {
                                onActivateQuestion();
                            } else {
                                activateQuestionApiInstanceQuestionPatch({
                                    quizId: quiz!._id!,
                                    questionId: questionId
                                }).then(r => {
                                    queryClient.invalidateQueries();
                                })
                            }
                        }}
                    />
                    {hasPreview &&
                        <IconButton
                            ml={2}
                            icon={<IoMdEye/>}
                            size={'xs'}
                            fontSize={'16px'}
                            aria-label={"Activate"}
                            onClick={openQuestionPreview}
                        />
                    }
                </HStack>
            </Td>
            <Td>
                <Text noOfLines={1}>
                    {
                        name
                    }
                </Text>
            </Td>
            <Td>
                {
                    type === undefined ? (isShowAnswer ? "Answer" : "Question") : type
                }
            </Td>
            {!hideAnswers &&
                <Td>
                    <ShowMoreText
                        lines={3}
                        more="Meer"
                        less="Minder"
                        width={150}
                        keepNewLines={true}
                    >
                        {
                            answer
                        }
                    </ShowMoreText>
                </Td>
            }
            {
                teams.map((t, i) =>
                    <Td key={`t-${i}`}>
                        {
                            answerElement(t)
                        }
                    </Td>
                )
            }
        </Tr>
    )
}

const GTDDefinitionElement = (question: GTDQuestionInstance, team: Team, active: boolean, stage: GTDQuestionStage) => {
    const quiz = useContext<QuizInstance | undefined>(QuizContext);
    const teamId = team._id!
    const hasDefinition = teamId in question.definitions && question.definitions[teamId] !== '';
    const hasSubmitted = question.submitted.includes(teamId);

    const startDefinition = hasDefinition ? question.definitions[teamId] : "";
    const [definition, setDefinition] = useState(startDefinition);
    const [isEditingByAdmin, setEditingByAdmin] = useState(false);

    useEffect(() => {
        if (!isEditingByAdmin) {
            setDefinition(startDefinition);
        }
    }, [hasSubmitted, question, isEditingByAdmin]);

    if (!quiz) {
        return null;
    }

    function EditableDefinitionControls() {
        const {
            isEditing,
            getSubmitButtonProps,
            getCancelButtonProps,
            getEditButtonProps,
        } = useEditableControls()

        useEffect(() => {
            setEditingByAdmin(isEditing);
        }, [isEditing])

        return isEditing ? (
            <ButtonGroup justifyContent='center'>
                <IconButton icon={<MdCheck/>}
                            variant='outline'
                            size='xs'
                            fontSize='10px'
                            aria-label={"Confirm"}
                            {...getSubmitButtonProps()} />
                <IconButton icon={<MdClose/>}
                            colorScheme='red'
                            variant='outline'
                            size='xs'
                            fontSize='10px'
                            aria-label={"Cancel"}
                            {...getCancelButtonProps()} />
            </ButtonGroup>
        ) : (
            <Flex justifyContent='center'>
                <IconButton icon={<BsPencil/>}
                            variant='outline'
                            size='xs'
                            fontSize='10px'
                            aria-label={"Edit"}
                            {...getEditButtonProps()} />
            </Flex>
        )
    }

    return (
        <HStack>
            {hasSubmitted && question.stage === GTDQuestionStage.COLLECTING_DEFINITIONS &&
                <Icon
                    aria-label='Complete'
                    fontSize='20px'
                    as={MdCheck}/>
            }
            {hasDefinition &&
                <Editable
                    defaultValue={startDefinition}
                    value={definition}
                    onSubmit={async () => {
                        await giveGtdDefinitionApiQuizGtdDefinitionPut({
                            definition: definition,
                            questionId: question._id!,
                            teamId: teamId
                        }).then(r => {
                            console.log("refreshing all clients");
                            refreshAllClientsApiRefreshAllPut();
                        })
                    }
                    }
                    onChange={(newValue) => setDefinition(newValue)}
                    isPreviewFocusable={false}
                >
                    <HStack>
                        <ShowMoreText
                            lines={3}
                            more="Meer"
                            less="Minder"
                            width={150}
                            keepNewLines={true}>
                            {
                                definition
                            }
                        </ShowMoreText>
                        {active && question.stage == stage &&
                            <>
                                <Input as={EditableInput} fontSize={'sm'} size={'sm'} width={'100px'}/>
                                <EditableDefinitionControls/>
                                <Checkbox
                                    isChecked={!question.skippedTeams.includes(team._id!)}
                                    onChange={async e => {
                                        const skip = !e.target.checked;
                                        await skipGtdDefinitionApiInstanceGtdSkipteamPatch({
                                            quizId: quiz._id!,
                                            questionId: question._id!,
                                            teamId: team._id!,
                                            skip: skip
                                        })
                                    }}/>
                            </>
                        }
                    </HStack>
                </Editable>
            }
            {!hasDefinition && !hasSubmitted && active && question.stage == stage &&
                <BeatLoader
                    color={themeGetter().colors.quizycolor.dark}
                    size={'5px'}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            }
        </HStack>
    )
}

const GTDQuestionController = (question: GTDQuestionInstance, teams: Team[], active: boolean, hideAnswer: boolean) => {
    const queryClient = useQueryClient();
    const quiz = useContext<QuizInstance | undefined>(QuizContext);
    if (!quiz) {
        return null;
    }

    return (
        <>
            {!question.question.isShowAnswer &&
                QuestionController(
                    () => <GTDInstanceState question={question} teams={teams} active={active}
                                            state={GTDQuestionStage.COLLECTING_DEFINITIONS}/>,
                    (t: Team) => GTDDefinitionElement(question, t, active, GTDQuestionStage.COLLECTING_DEFINITIONS),
                    question,
                    question.question.name,
                    active && question.stage === GTDQuestionStage.COLLECTING_DEFINITIONS,
                    hideAnswer,
                    teams,
                    () => {
                        activateGtdQuestionApiInstanceGtdQuestionPatch({
                            quizId: quiz!._id!,
                            questionId: question._id!,
                            stage: GTDQuestionStage.COLLECTING_DEFINITIONS
                        }).then(r => {
                            queryClient.invalidateQueries();
                        })
                    },
                    'Definitions',
                    active && question.stage === GTDQuestionStage.COLLECTING_DEFINITIONS
                )
            }
            {
                QuestionController(
                    () => <GTDInstanceState question={question} teams={teams} active={active}
                                            state={GTDQuestionStage.DEFINITION_CHOICE}/>,
                    (t: Team) => <StringAnswer
                        active={active && question.stage === GTDQuestionStage.DEFINITION_CHOICE}
                        answers={Object.entries(question.answers).reduce((o, entry) => Object.assign(o, {[entry[0]]: [entry[1]]}), {})}
                        submitted={question.submitted.includes(t._id!)}
                        t={t}
                        q={question}
                    />,
                    question,
                    question.question.name,
                    active && question.stage === GTDQuestionStage.DEFINITION_CHOICE,
                    hideAnswer,
                    teams,
                    () => {
                        activateGtdQuestionApiInstanceGtdQuestionPatch({
                            quizId: quiz!._id!,
                            questionId: question._id!,
                            stage: GTDQuestionStage.DEFINITION_CHOICE
                        }).then(r => {
                            queryClient.invalidateQueries();
                        })
                    },
                    'Choice',
                    active && question.stage === GTDQuestionStage.DEFINITION_CHOICE
                )
            }
            {
                QuestionController(
                    () => <GTDInstanceState question={question} teams={teams} active={active}
                                            state={GTDQuestionStage.SHOW_ANSWER}/>,
                    (t: Team) => (<Text>-</Text>),
                    question,
                    question.question.name,
                    active && question.stage === GTDQuestionStage.SHOW_ANSWER,
                    hideAnswer,
                    teams,
                    () => {
                        activateGtdQuestionApiInstanceGtdQuestionPatch({
                            quizId: quiz!._id!,
                            questionId: question._id!,
                            stage: GTDQuestionStage.SHOW_ANSWER
                        }).then(r => {
                            queryClient.invalidateQueries();
                        })
                    },
                    'Answer',
                    active && question.stage === GTDQuestionStage.SHOW_ANSWER
                )
            }
        </>
    )
}

export {
    useAnswerPoints,
    useTeamPoints,
    QuestionInstanceState,
    StringAnswer,
    BlankQuestionController,
    QuestionController,
    GTDQuestionController
}
