import {
    BaseQuestion,
    BlankQuestion,
    DisplaySize, GTDQuestion,
    MultipleAnswerQuestion, MultipleChoiceQuestion, OpenQuestion,
    QuestionType,
    QuizQuestionsItem
} from "../../../../Client/Models";
import {discriminateQuestion} from "../../../Utils/QuestionDiscriminator";
import React from "react";


export const DefaultQuestionBuilder = (t: QuestionType) =>
{
    const base = {
        name: "",
        isShowAnswer: false,
        displaySize: DisplaySize.NORMAL,
    }

    return discriminateQuestion<QuizQuestionsItem>(
        {type: t} as BaseQuestion,
        {
            Blank: q => {
                return {
                    ...base,
                    question: '',
                    type: t,
                }
            },
            Open: q => {
                return {
                    ...base,
                    question: '',
                    answer: '',
                    multiline: false,
                    type: t,
                }
            },
            MultipleChoice: q => {
                return {
                    ...base,
                    question: '',
                    options: [],
                    answer: '',
                    type: t,
                }
            },
            MultipleAnswer: q => {
                return {
                    ...base,
                    question: '',
                    options: [],
                    answers: [],
                    type: t,
                }
            },
            GuessTheDefinition: q => {
                return {
                    ...base,
                    word: '',
                    definition: '',
                    type: t,
                }
            },
            default: q => {
                return { } as QuizQuestionsItem
            }
        }
    )
}

export const QuestionPopulator = (question: QuizQuestionsItem) =>
{
    return discriminateQuestion<QuizQuestionsItem>(
        question,
        {
            Blank: (q) => {
                // Populate missing
                return {
                    ...DefaultQuestionBuilder(q.type!),
                    ...question,
                } as BlankQuestion;
            },
            Open: (q) => {
                // Populate missing
                return {
                    ...DefaultQuestionBuilder(q.type!),
                    ...question,
                } as OpenQuestion
            },
            MultipleChoice: (q) => {
                // Populate missing
                return {
                    ...DefaultQuestionBuilder(q.type!),
                    ...question,
                } as MultipleChoiceQuestion
            },
            MultipleAnswer: (q) => {
                // Populate missing
                return {
                    ...DefaultQuestionBuilder(q.type!),
                    ...question,
                } as MultipleAnswerQuestion
            },
            GuessTheDefinition: (q) => {
                // Populate missing
                return {
                    ...DefaultQuestionBuilder(q.type!),
                    ...question,
                    isShowAnswer: false
                } as GTDQuestion
            },
            default: () => question
        }
    );
}
