import {Center, Flex, Spinner, Text} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {BiSad} from "react-icons/bi";
import * as React from "react";
import {Link as ReactLink} from "react-router-dom";

export default function Loading({homeFallback = true, oopsFallback = false}) {
    const [hiddenFallback, setHiddenFallback] = useState(true);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        let isCancelled = false;

        setTimeout(function () {
            if (!isCancelled) {
                setHiddenFallback(false);
            }
        }, 600);

        if (oopsFallback) {
            setTimeout(function () {
                if (!isCancelled) {
                    setLoading(false);
                }
            }, 6000);
        }

        return () => {
            isCancelled = true;
        };
    }, []);

    // Hide until not hidden
    if (hiddenFallback) {
        return null;
    }

    // If loading, show spinner
    if (isLoading) {
        return (
            <Center w={"100%"}>
                <Spinner m={10}/>
            </Center>
        )
    }

    // When loading for a long time, show warning
    if (homeFallback) {
        return (
            <Center w={"100%"}>
                <Flex as={ReactLink} to={'/'} m={20} alignItems={"center"}>
                    <Spinner/>
                    <Text ml={4} fontSize="2l" fontFamily="monospace" fontWeight="bold">
                        This is taking long, click to go back to home.
                    </Text>
                </Flex>
            </Center>
        )
    }

    return (
        <Center w={"100%"}>
            <Flex m={20} alignItems={"center"}>
                <BiSad size={50}/>
                <Text ml={4} fontSize="2l" fontFamily="monospace" fontWeight="bold">
                    Could not load data, check back later.
                </Text>
            </Flex>
        </Center>
    )
}
