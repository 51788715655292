import {BlankQuestion, OpenQuestion, QuizQuestionsItem} from "../../../../../Client/Models";
import React from "react";
import {Checkbox, Input, Textarea} from "@chakra-ui/react";
import {BaseQuestionEditor} from "./BaseQuestionEditor";

const OpenQuestionEditor = (props: {
    question: OpenQuestion,
    onSetQuestion: (question: QuizQuestionsItem) => void
    children?: React.ReactNode
}) => {
    return (
        <BaseQuestionEditor
            question={props.question as BlankQuestion}
            onSetQuestion={props.onSetQuestion}
            hasShowAnswer={true}>
            <Checkbox
                isChecked={props.question.multiline}
                onChange={e => {
                    props.onSetQuestion({...props.question, multiline: e.target.checked})
                }}>
                Multi-line
            </Checkbox>
            {!props.question.multiline &&
                <Input
                    placeholder='Antwoord'
                    value={props.question.answer}
                    onChange={(e) =>
                        props.onSetQuestion({...props.question, answer: e.target.value})
                    }
                />
            }
            {props.question.multiline &&
                <Textarea
                    placeholder='Antwoord'
                    value={props.question.answer}
                    onChange={(e) =>
                        props.onSetQuestion({...props.question, answer: e.target.value})
                    }
                />
            }
        </BaseQuestionEditor>
    )
}

export {
    OpenQuestionEditor
}
