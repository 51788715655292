import {
    Button, Checkbox,
    Divider,
    Heading, Highlight,
    HStack,
    Input,
    Radio,
    Spacer, Stack,
    Textarea, useCheckboxGroup, useRadioGroup,
    VStack,
    Text, Flex, Center, Box, baseTheme, Icon, Circle
} from "@chakra-ui/react";
import React, {useEffect, useRef, useState} from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import ChakraUIRenderer from "chakra-ui-markdown-renderer";
import {
    GTDQuestion,
    GTDQuestionInstance, GTDQuestionStage,
    MultipleAnswerQuestion,
    MultipleChoiceQuestion,
    OpenQuestion,
    QuizInstanceQuestionsItem,
    StringQuestionInstance,
    Team
} from "../../Client/Models";
import {useMutation, useQueryClient} from "react-query";
import {
    getGetQuizQuestionApiQuizGetQueryKey,
    giveAnswerStringApiQuizStringPut, giveGtdAnswerApiQuizGtdAnswerPut, giveGtdDefinitionApiQuizGtdDefinitionPut,
    submitApiQuizSubmitPut,
    unsubmitApiQuizUnsubmitPut, useGetGtdDefinitionsApiQuizGtdDefinitionGet,
} from "../../Client/default/default";
import {discriminateQuestionInstance} from "../Utils/QuestionDiscriminator";
import Loading from "../Utils/Loading";
import {EmptyMessage} from "../Utils/EmptyMessage";
import rehypeRaw from "rehype-raw";
import {MdCheck, MdClose} from "react-icons/md";

const QuestionMarkdownViewer = (props: { markdown: string }) => {
    return (
        <VStack>
            <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} components={ChakraUIRenderer()}>
                {props.markdown}
            </ReactMarkdown>
        </VStack>
    )
}

const useStringQuestionMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (data: { questionId: string, teamId: string, answer: string[] }) => {
            await giveAnswerStringApiQuizStringPut(data.answer, {
                questionId: data.questionId,
                teamId: data.teamId
            }).catch(e => {
                console.log("Answer string error ignored")
            })
        },
        onMutate: async (data: { questionId: string, teamId: string, answer: string[] }) => {
            const queryKey = getGetQuizQuestionApiQuizGetQueryKey();

            // Stop the queries that may affect this operation
            //await queryClient.cancelQueries(queryKey);
            // Get a snapshot of current data
            const queryDataSnapshot: any = queryClient.getQueryData(queryKey)!;


            // Optimistic update
            queryClient.setQueryData<any>(queryKey, (oldQueryData: any) => {
                if (!oldQueryData || !oldQueryData.data) {
                    return undefined;
                }
                let quizQuestionInstance: QuizInstanceQuestionsItem = oldQueryData.data;

                discriminateQuestionInstance<() => void>(
                    quizQuestionInstance,
                    {
                        Blank: q => () => {
                        },
                        Open: q => () => {
                            q.answers[data.teamId] = data.answer;
                        },
                        MultipleChoice: q => () => {
                            q.answers[data.teamId] = data.answer;
                        },
                        MultipleAnswer: q => () => {
                            q.answers[data.teamId] = data.answer;
                        },
                        GuessTheDefinition: q => () => {
                            if (q.stage === GTDQuestionStage.COLLECTING_DEFINITIONS) {
                                q.definitions[data.teamId] = data.answer[0];
                            } else {
                                q.answers[data.teamId] = data.answer[0];
                            }
                        },
                        default: q => () => {
                        }
                    }
                )();

                return oldQueryData;
            });

            // Return a snapshot for rollback
            return {
                queryDataSnapshot
            }
        },
        onError: (error, variables, context) => {
            if (context) {
                // Rollback
                queryClient.setQueryData(getGetQuizQuestionApiQuizGetQueryKey(), context.queryDataSnapshot)
            }
        },
        async onSettled() {
            await queryClient.invalidateQueries(getGetQuizQuestionApiQuizGetQueryKey())
        }
    })
}

const useGTDMutation = (stage: GTDQuestionStage) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (data: { questionId: string, teamId: string, answer: string }) => {
            if (stage === GTDQuestionStage.COLLECTING_DEFINITIONS) {
                await giveGtdDefinitionApiQuizGtdDefinitionPut({
                    definition: data.answer,
                    questionId: data.questionId,
                    teamId: data.teamId
                })
            } else {
                await giveGtdAnswerApiQuizGtdAnswerPut({
                    answer: data.answer,
                    questionId: data.questionId,
                    teamId: data.teamId
                })
            }
        },
        onMutate: async (data: { questionId: string, teamId: string, answer: string }) => {
            const queryKey = getGetQuizQuestionApiQuizGetQueryKey();

            // Stop the queries that may affect this operation
            //await queryClient.cancelQueries(queryKey);
            // Get a snapshot of current data
            const queryDataSnapshot: any = queryClient.getQueryData(queryKey)!;

            // Optimistic update
            queryClient.setQueryData<any>(queryKey, (oldQueryData: any) => {
                if (!oldQueryData || !oldQueryData.data) {
                    return undefined;
                }
                let quizQuestionInstance: GTDQuestionInstance = oldQueryData.data;

                if (stage === GTDQuestionStage.COLLECTING_DEFINITIONS) {
                    quizQuestionInstance.definitions[data.teamId] = data.answer;
                } else {
                    quizQuestionInstance.answers[data.teamId] = data.answer;
                }

                return oldQueryData;
            });

            // Return a snapshot for rollback
            return {
                queryDataSnapshot
            }
        },

        onError: (error, variables, context) => {
            if (context) {
                // Rollback
                queryClient.setQueryData(getGetQuizQuestionApiQuizGetQueryKey(), context.queryDataSnapshot)
            }
        },
        async onSettled() {
            await queryClient.invalidateQueries(getGetQuizQuestionApiQuizGetQueryKey())
        }
    })
}

const QuestionSubmitButtonGroup = (props: { questionId: string, teamId: string, submitted: boolean, announce: () => Promise<void> }) => {
    const queryClient = useQueryClient();
    return (
        <HStack w={"100%"}>
            <Spacer/>
            <Button
                variant={props.submitted ? 'outline' : 'solid'}
                colorScheme={props.submitted ? 'red' : 'orange'}
                onClick={async () => {
                    if (props.submitted) {
                        console.log("Unsubmitting...")
                        unsubmitApiQuizUnsubmitPut({
                            questionId: props.questionId!,
                            teamId: props.teamId!
                        }).then(() => {
                            queryClient.invalidateQueries(getGetQuizQuestionApiQuizGetQueryKey())
                        })
                    } else {
                        console.log("Submitting...")
                        props.announce().then(r =>
                            submitApiQuizSubmitPut({
                                questionId: props.questionId!,
                                teamId: props.teamId!
                            }).then(() => {
                                queryClient.invalidateQueries(getGetQuizQuestionApiQuizGetQueryKey())
                            })
                        );
                    }
                }}>
                {props.submitted ? "Antwoord veranderen" : "Indienen"}
            </Button>
        </HStack>
    )
}

const OpenQuestionViewer = (props: { instance: StringQuestionInstance, team?: Team }) => {
    const quizAnswerMutation = useStringQuestionMutation();
    const inputRef = useRef(null);

    let answers = props.instance.answers;
    let realAnswer = "";
    if (props.team && props.team._id! in answers) {
        realAnswer = answers[props.team._id!][0];
    }

    const [answer, setAnswer] = useState(realAnswer);
    let blockUpdate: boolean = false;

    const announceAnswers = async () => {
        if (question.isShowAnswer) {
            return;
        }

        blockUpdate = true;
        await quizAnswerMutation.mutateAsync({
            questionId: props.instance._id!,
            teamId: props.team!._id!,
            answer: [answer]
        });
    }

    let question = props.instance.question as OpenQuestion
    let submitted = !props.team || props.instance.submitted.includes(props.team._id!);

    useEffect(() => {
        const timeOutId = setTimeout(async () => {
            if (props.team && !submitted && !blockUpdate) {
                await announceAnswers();
            }
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [answer]);

    useEffect(() => {
        if (document.activeElement !== inputRef.current || submitted) {
            setAnswer(realAnswer);
        }
    }, [submitted, realAnswer]);

    return (
        <VStack>
            <QuestionMarkdownViewer markdown={question.question}/>
            <Divider my={3}/>
            {question.isShowAnswer &&
                question.answer.split('\n').map((e, i) =>
                    <Text key={i}>{e}</Text>
                )
            }
            {!question.multiline && !question.isShowAnswer &&
                <Input
                    ref={inputRef}
                    placeholder='Antwoord'
                    value={answer}
                    disabled={submitted}
                    onChange={(e) => setAnswer(e.target.value)}
                />
            }
            {question.multiline && !question.isShowAnswer &&
                <Textarea
                    placeholder={props.team ? 'Antwoord' : 'U kunt geen antwoord geven'}
                    value={answer}
                    disabled={submitted}
                    onChange={(e) => setAnswer(e.target.value)}
                />
            }
            {props.team && !question.isShowAnswer &&
                <QuestionSubmitButtonGroup
                    questionId={props.instance._id!}
                    teamId={props.team._id!}
                    submitted={submitted}
                    announce={async () => {
                        await announceAnswers();
                    }
                    }/>
            }
        </VStack>
    )
}

const MultipleChoiceViewer = (props: {
    questionId: string,
    teamId: string,
    questionElement: React.ReactElement | null,
    options: string[],
    onMutate: (answer: string) => Promise<void>,
    startInput: string,
    answer: string,
    hasTeam: boolean,
    submitted: boolean,
    isShowAnswer: boolean,
}) => {
    const announceAnswers = async (a: string | undefined = undefined) => {
        if (props.isShowAnswer) {
            return;
        }
        if (a === undefined) {
            a = value.toString();
        }

        await props.onMutate(a);
    };

    const {value, getRadioProps} = useRadioGroup({
        value: props.startInput,
        onChange: async (a) => {
            await announceAnswers(a);
        },
    });

    return (
        <VStack>
            {
                props.questionElement
            }
            <Divider my={3}/>
            <Stack>
                {
                    props.options.map((o, i) => {
                        let correct = (o === props.answer);
                        let wrong = !correct && (o === value);

                        if (props.isShowAnswer && correct) {
                            return (
                                <HStack>
                                    <Circle bg={baseTheme.colors.whiteAlpha['300']}>
                                        <Icon
                                            color={'green'}
                                            aria-label='Complete'
                                            as={MdCheck}/>
                                    </Circle>
                                    <Text
                                        color={'white'}
                                        opacity={0.7}
                                        key={i}>
                                        {o}
                                    </Text>
                                </HStack>
                            )
                        }

                        if (props.isShowAnswer && wrong) {
                            return (
                                <HStack>
                                    <Circle bg={baseTheme.colors.whiteAlpha['300']}>
                                        <Icon
                                            color={'red'}
                                            aria-label='Wrong'
                                            as={MdClose}/>
                                    </Circle>
                                    <Text
                                        color={'white'}
                                        opacity={0.7}
                                        key={i}>
                                        {o}
                                    </Text>
                                </HStack>
                            )
                        }

                return (
                <Radio
                    key={i}
                    isDisabled={
                        !props.hasTeam ||
                        (props.submitted && !props.isShowAnswer) ||
                        props.isShowAnswer
                    }
                    {...getRadioProps({value: o})}>
                    {o}
                </Radio>
                )
                }
                )
                }
            </Stack>
            {props.hasTeam && !props.isShowAnswer &&
            <QuestionSubmitButtonGroup
                questionId={props.questionId}
                teamId={props.teamId}
                submitted={props.submitted}
                announce={announceAnswers}/>
            }
        </VStack>
    )
}

    const MultipleChoiceQuestionViewer = (props: {
        instance: StringQuestionInstance,
        team?: Team
    }) => {
        const quizAnswerMutation = useStringQuestionMutation();
        let startInput = "";
        let answers = props.instance.answers;
        if (props.team && props.team._id! in answers) {
            startInput = answers[props.team._id!][0];
        }

        const question = props.instance.question as MultipleChoiceQuestion;

        return <MultipleChoiceViewer
            questionElement={<QuestionMarkdownViewer markdown={props.instance.question.question}/>}
            startInput={startInput}
            onMutate={async (a) => {
                await quizAnswerMutation.mutateAsync({
                    questionId: props.instance._id!,
                    teamId: props.team!._id!,
                    answer: [a]
                });
            }
            }
            questionId={props.instance._id!}
            teamId={props.team !== undefined ? props.team!._id! : ""}
            options={question.options}
            answer={question.answer}
            hasTeam={props.team !== undefined}
            submitted={props.team !== undefined && props.instance.submitted.includes(props.team!._id!)}
            isShowAnswer={question.isShowAnswer}
        />
    }

    const MultipleAnswerQuestionViewer = (props: {
        instance: StringQuestionInstance,
        team?: Team
    }) => {
        const quizAnswerMutation = useStringQuestionMutation();

        let instanceAnswers = props.instance.answers;
        let realAnswer: string[] = [];
        if (props.team && props.team._id! in instanceAnswers) {
            realAnswer = instanceAnswers[props.team._id!];
        }

        const announceAnswers = async (a: string[] | undefined = undefined) => {
            if (question.isShowAnswer) {
                return;
            }
            if (a === undefined) {
                a = value.map(i => i.toString());
            }
            await quizAnswerMutation.mutateAsync({
                questionId: props.instance._id!,
                teamId: props.team!._id!,
                answer: a
            });
        }

        let question = props.instance.question as MultipleAnswerQuestion
        let submitted = !props.team || props.instance.submitted.includes(props.team._id!);

        if (question.isShowAnswer) {
            realAnswer = question.answers;
        }

        const {value, getCheckboxProps} = useCheckboxGroup({
            value: realAnswer,
            onChange: async (e) => {
                const a = e.map(i => i.toString());
                await announceAnswers(a);
            },
        });

        return (
            <VStack>
                <QuestionMarkdownViewer markdown={props.instance.question.question}/>
                <Divider my={3}/>
                {!question.isShowAnswer &&
                    <Text>
                        <Highlight
                            query='meerdere'
                            styles={{px: '2', py: '0.5', rounded: 'full', bg: 'orange.600', color: 'white'}}
                        >
                            Er kunnen meerdere antwoorden goed zijn.
                        </Highlight>
                    </Text>
                }
                <Stack>
                    {
                        question.options.map((o, i) => {
                                let correct = question.answers.includes(o);
                                return (
                                    <Checkbox
                                        isDisabled={
                                            (submitted && !question.isShowAnswer) || (question.isShowAnswer && !correct)
                                        }
                                        key={i}
                                        {...getCheckboxProps({value: o})}>
                                        {o}
                                    </Checkbox>
                                )
                            }
                        )
                    }
                </Stack>
                {props.team && !question.isShowAnswer &&
                    <QuestionSubmitButtonGroup
                        questionId={props.instance._id!}
                        teamId={props.team._id!}
                        submitted={submitted}
                        announce={announceAnswers}/>
                }
            </VStack>
        )
    }

    const GTDQuestionElement = (props: { text: string, word: string }) => {
        return (
            <>
                <Heading size={'lg'}>
                    {props.text}
                </Heading>
                <Center>
                    <Flex background={'quizycolor.mediumlight'} px={7} py={2} borderRadius={'full'} color={'black'}>
                        <Text fontSize={'lg'}>
                            {props.word}
                        </Text>
                    </Flex>
                </Center>
            </>
        )
    }

    const GTDQuestionDefinitionViewer = (props: { instance: GTDQuestionInstance, team?: Team, startInput: string }) => {
        const quizAnswerMutation = useGTDMutation(props.instance.stage);

        const [answer, setAnswer] = useState(props.startInput);
        let blockUpdate: boolean = false;

        const announceAnswers = async () => {
            if (props.instance.question.isShowAnswer) {
                return;
            }

            blockUpdate = true;
            await quizAnswerMutation.mutateAsync({
                questionId: props.instance._id!,
                teamId: props.team!._id!,
                answer: answer
            });
        }

        let submitted = !props.team || props.instance.submitted.includes(props.team._id!);

        useEffect(() => {
            const timeOutId = setTimeout(async () => {
                if (props.team && !submitted && !blockUpdate) {
                    await announceAnswers();
                }
            }, 500);
            return () => clearTimeout(timeOutId);
        }, [answer]);

        useEffect(() => {
            setAnswer(props.startInput);
        }, [submitted]);

        return (
            <Stack>
                <GTDQuestionElement text={"Bedenk een definitie voor dit woord"}
                                    word={props.instance.question.word}/>
                <Divider my={3}/>
                <Input
                    placeholder='Definitie'
                    value={answer}
                    disabled={submitted}
                    onChange={(e) => setAnswer(e.target.value)}
                />
                {props.team && !props.instance.question.isShowAnswer &&
                    <QuestionSubmitButtonGroup
                        questionId={props.instance._id!}
                        teamId={props.team._id!}
                        submitted={submitted}
                        announce={announceAnswers}/>
                }
            </Stack>
        )
    }

    const GTDQuestionAnswerPicker = (props: { instance: GTDQuestionInstance, team?: Team, startInput: string }) => {
        const question = props.instance.question as GTDQuestion;
        const quizAnswerMutation = useGTDMutation(props.instance.stage);

        const hasTeam = props.team !== undefined;

        const {isLoading, isError, data: optionsData} = useGetGtdDefinitionsApiQuizGtdDefinitionGet({
            questionId: props.instance._id!
        });

        if (isLoading) {
            return <Loading/>;
        }
        if (!optionsData) {
            return <EmptyMessage text={"Error fetching question options"}/>
        }

        return <MultipleChoiceViewer
            questionElement={<GTDQuestionElement text={"Wat betekent dit woord?"} word={question.word}/>}
            startInput={props.startInput}
            onMutate={async (a) => {
                await quizAnswerMutation.mutateAsync({
                    questionId: props.instance._id!,
                    teamId: props.team!._id!,
                    answer: a
                });
            }
            }
            questionId={props.instance._id!}
            teamId={hasTeam ? props.team!._id! : ""}
            options={optionsData.data as string[]}
            answer={question.definition}
            hasTeam={hasTeam}
            submitted={hasTeam && props.instance.submitted.includes(props.team!._id!)}
            isShowAnswer={props.instance.stage === GTDQuestionStage.SHOW_ANSWER}
        />
    }

    const GTDQuestionViewer = (props: { instance: GTDQuestionInstance, team?: Team }) => {
        let instanceAnswers = props.instance.stage === GTDQuestionStage.COLLECTING_DEFINITIONS ?
            props.instance.definitions : props.instance.answers;
        let startInput: string = "";
        if (props.team && props.team._id! in instanceAnswers) {
            startInput = instanceAnswers[props.team._id!];
        }

        if (props.instance.stage === GTDQuestionStage.COLLECTING_DEFINITIONS) {
            // Return a special component for collecting the definitions
            return (
                <GTDQuestionDefinitionViewer instance={props.instance} team={props.team} startInput={startInput}/>
            )
        } else {
            // The second part is basically a MultipleChoiceQuestion in disguise, so create it.
            return (
                <GTDQuestionAnswerPicker instance={props.instance} team={props.team} startInput={startInput}/>
            )
        }
    }

    export {
        OpenQuestionViewer,
        QuestionMarkdownViewer,
        MultipleChoiceQuestionViewer,
        MultipleAnswerQuestionViewer,
        GTDQuestionViewer
    }
