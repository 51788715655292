import React from "react";
import {
    BlankQuestion, BlankQuestionInstance, GTDQuestionInstance, GTDQuestion,
    MultipleAnswerQuestion,
    MultipleChoiceQuestion,
    OpenQuestion,
    QuestionType, QuizInstanceQuestionsItem,
    QuizQuestionsItem, StringQuestionInstance
} from "../../Client/Models";

interface QuestionDiscriminatorProps<T> {
    Blank: (q: BlankQuestion) => T;
    Open: (q: OpenQuestion) => T;
    MultipleAnswer: (q : MultipleAnswerQuestion) => T;
    MultipleChoice: (q : MultipleChoiceQuestion) => T;
    GuessTheDefinition: (q: GTDQuestion) => T;
    default: (q : QuizQuestionsItem) => T;
}

function discriminateQuestion<T>(question: Partial<QuizQuestionsItem>, params: QuestionDiscriminatorProps<T>) {
    if (question.type === QuestionType.BLANK) {
        return params.Blank(question as BlankQuestion);
    } else if (question.type === QuestionType.OPEN) {
        return params.Open(question as OpenQuestion);
    } else if (question.type === QuestionType.MULTIPLE_CHOICE) {
        return params.MultipleChoice(question as MultipleChoiceQuestion);
    } else if (question.type === QuestionType.MULTIPLE_ANSWER) {
        return params.MultipleAnswer(question as MultipleAnswerQuestion);
    } else if (question.type === QuestionType.GUESS_THE_DEFINITION) {
        return params.GuessTheDefinition(question as GTDQuestion);
    }

    return params.default(question as QuizQuestionsItem);
}

interface QuestionInstanceDiscriminatorProps<T> {
    Blank: (q: BlankQuestionInstance) => T;
    Open: (q: StringQuestionInstance) => T;
    MultipleAnswer: (q : StringQuestionInstance) => T;
    MultipleChoice: (q : StringQuestionInstance) => T;
    GuessTheDefinition: (q: GTDQuestionInstance) => T;
    default: (q : QuizInstanceQuestionsItem) => T;
}


function discriminateQuestionInstance<T>(instance: QuizInstanceQuestionsItem, params: QuestionInstanceDiscriminatorProps<T>) {
    return discriminateQuestion(
        instance.question,
        {
            Blank : (q) => params.Blank(instance as BlankQuestionInstance),
            Open : (q) => params.Open(instance as StringQuestionInstance),
            MultipleAnswer : (q) => params.MultipleAnswer(instance as StringQuestionInstance),
            MultipleChoice : (q) => params.MultipleChoice(instance as StringQuestionInstance),
            GuessTheDefinition : (q) => params.GuessTheDefinition(instance as GTDQuestionInstance),
            default : (q) => params.default(instance),
        }
    )
}

export {
    discriminateQuestion,
    discriminateQuestionInstance
}
