import {Center, Icon, Text} from "@chakra-ui/react";
import {RiEmotionSadLine} from "react-icons/ri";
import React from "react";

const EmptyMessage = (props: {text: string}) => {
    return (
        <Center>
            <Icon
                aria-label='Sad smiley'
                fontSize='20px'
                mx={2}
                as={RiEmotionSadLine}/>
            <Text>
                {props.text}
            </Text>
        </Center>
    )
}

export {
    EmptyMessage
}
