import {AnimateMount} from "../Utils/Animation";
import {Box, Heading, VStack} from "@chakra-ui/react";
import Lottie from "lottie-react";
import teaAndCoffeeLottie from "../../assets/lotties/tea-and-coffee.json";
import React from "react";

export const WaitingForQuiz = () => {
    return (
        <AnimateMount customKey={'quiz-wait-lottie'}>
            <VStack>
                <Heading as='h3' size='lg' mb={5}>
                    Wachten op de quiz
                </Heading>
                <Box h={"70%"} w={"70%"}>
                    <Lottie
                        animationData={teaAndCoffeeLottie}
                    />
                </Box>
            </VStack>
        </AnimateMount>
    )
}
