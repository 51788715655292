import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Team} from "../../Client/Models";

interface TeamState {
    hasTeam: boolean,
    team: Team
}

const initialState: TeamState = {
    hasTeam: false,
    team: {
        _id: "",
        name: ""
    }
};

const teamSlice = createSlice({
    name: 'team',
    initialState,
    reducers: {
        setTeam: (state, action: PayloadAction<Team>) => {
            return {
                ...state,
                team: action.payload
            }
        },
    },
})

export const {setTeam} = teamSlice.actions

export default teamSlice.reducer
