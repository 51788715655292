/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Quizy backend
 * OpenAPI spec version: 0.1.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  Team,
  HTTPValidationError,
  RemoveTeamApiTeamsDeleteParams,
  RenameTeamApiTeamsPatchParams,
  GetTeamApiTeamsIdGetParams,
  AddQuestionApiTemplatesQuestionsPutBody,
  AddQuestionApiTemplatesQuestionsPutParams,
  RemoveQuestionApiTemplatesQuestionsDeleteParams,
  ModifyQuestionApiTemplatesQuestionsPatchBody,
  ModifyQuestionApiTemplatesQuestionsPatchParams,
  DuplicateQuestionApiTemplatesQuestionsDuplicatePutParams,
  ElevateQuestionApiTemplatesQuestionsElevatePost200,
  ElevateQuestionApiTemplatesQuestionsElevatePostBody,
  GetQuestionApiTemplatesQuestionsIdGet200,
  GetQuestionApiTemplatesQuestionsIdGetParams,
  MoveQuestionApiTemplatesQuestionsMovePostParams,
  SetQuestionsApiTemplatesQuestionsAllPostBodyItem,
  SetQuestionsApiTemplatesQuestionsAllPostParams,
  Quiz,
  RemoveQuizApiTemplatesDeleteParams,
  RenameQuizApiTemplatesPatchParams,
  DuplicateQuizApiTemplatesDuplicatePutParams,
  GetQuizApiTemplatesIdGetParams,
  QuizInstance,
  RemoveInstanceApiInstanceDeleteParams,
  RenameInstanceApiInstancePatchParams,
  SetActivationApiInstanceActivatePatchParams,
  ActivateQuestionApiInstanceQuestionPatchParams,
  SkipGtdDefinitionApiInstanceGtdSkipteamPatchParams,
  ActivateGtdQuestionApiInstanceGtdQuestionPatchParams,
  MoveApiInstanceMovePatchParams,
  GetQuizApiInstanceIdGetParams,
  SetBackbuttonVisisbleApiInstanceBackbuttonPutParams,
  CreateInstanceApiInstanceCreatePutParams,
  GiveAnswerStringApiQuizStringPutParams,
  GetGtdDefinitionsApiQuizGtdDefinitionGetParams,
  GiveGtdDefinitionApiQuizGtdDefinitionPutParams,
  GiveGtdAnswerApiQuizGtdAnswerPutParams,
  SubmitApiQuizSubmitPutParams,
  UnsubmitApiQuizUnsubmitPutParams,
  GetQuizQuestionApiQuizGet200,
  BodyUploadDataUploadPost
} from '.././Models'


/**
 * @summary Get Teams
 */
export const getTeamsApiTeamsGet = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Team[]>> => {
    return axios.get(
      `/api/teams/`,options
    );
  }


export const getGetTeamsApiTeamsGetQueryKey = () => [`/api/teams/`] as const;
  

    
export const getGetTeamsApiTeamsGetQueryOptions = <TData = Awaited<ReturnType<typeof getTeamsApiTeamsGet>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTeamsApiTeamsGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getTeamsApiTeamsGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTeamsApiTeamsGetQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTeamsApiTeamsGet>>> = ({ signal }) => getTeamsApiTeamsGet({ signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetTeamsApiTeamsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getTeamsApiTeamsGet>>>
export type GetTeamsApiTeamsGetQueryError = AxiosError<unknown>

/**
 * @summary Get Teams
 */
export const useGetTeamsApiTeamsGet = <TData = Awaited<ReturnType<typeof getTeamsApiTeamsGet>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTeamsApiTeamsGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTeamsApiTeamsGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * @summary Create Team
 */
export const createTeamApiTeamsPut = (
    team: Team, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Team>> => {
    return axios.put(
      `/api/teams/`,
      team,options
    );
  }



export const getCreateTeamApiTeamsPutMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTeamApiTeamsPut>>, TError,{data: Team}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createTeamApiTeamsPut>>, TError,{data: Team}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTeamApiTeamsPut>>, {data: Team}> = (props) => {
          const {data} = props ?? {};

          return  createTeamApiTeamsPut(data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateTeamApiTeamsPutMutationResult = NonNullable<Awaited<ReturnType<typeof createTeamApiTeamsPut>>>
    export type CreateTeamApiTeamsPutMutationBody = Team
    export type CreateTeamApiTeamsPutMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Team
 */
export const useCreateTeamApiTeamsPut = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTeamApiTeamsPut>>, TError,{data: Team}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getCreateTeamApiTeamsPutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Remove Team
 */
export const removeTeamApiTeamsDelete = (
    params: RemoveTeamApiTeamsDeleteParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.delete(
      `/api/teams/`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getRemoveTeamApiTeamsDeleteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeTeamApiTeamsDelete>>, TError,{params: RemoveTeamApiTeamsDeleteParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof removeTeamApiTeamsDelete>>, TError,{params: RemoveTeamApiTeamsDeleteParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeTeamApiTeamsDelete>>, {params: RemoveTeamApiTeamsDeleteParams}> = (props) => {
          const {params} = props ?? {};

          return  removeTeamApiTeamsDelete(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RemoveTeamApiTeamsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof removeTeamApiTeamsDelete>>>
    
    export type RemoveTeamApiTeamsDeleteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Remove Team
 */
export const useRemoveTeamApiTeamsDelete = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeTeamApiTeamsDelete>>, TError,{params: RemoveTeamApiTeamsDeleteParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getRemoveTeamApiTeamsDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Rename Team
 */
export const renameTeamApiTeamsPatch = (
    params: RenameTeamApiTeamsPatchParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Team>> => {
    return axios.patch(
      `/api/teams/`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getRenameTeamApiTeamsPatchMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameTeamApiTeamsPatch>>, TError,{params: RenameTeamApiTeamsPatchParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof renameTeamApiTeamsPatch>>, TError,{params: RenameTeamApiTeamsPatchParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof renameTeamApiTeamsPatch>>, {params: RenameTeamApiTeamsPatchParams}> = (props) => {
          const {params} = props ?? {};

          return  renameTeamApiTeamsPatch(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RenameTeamApiTeamsPatchMutationResult = NonNullable<Awaited<ReturnType<typeof renameTeamApiTeamsPatch>>>
    
    export type RenameTeamApiTeamsPatchMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Rename Team
 */
export const useRenameTeamApiTeamsPatch = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameTeamApiTeamsPatch>>, TError,{params: RenameTeamApiTeamsPatchParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getRenameTeamApiTeamsPatchMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Get Team
 */
export const getTeamApiTeamsIdGet = (
    params: GetTeamApiTeamsIdGetParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Team>> => {
    return axios.get(
      `/api/teams/id`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetTeamApiTeamsIdGetQueryKey = (params: GetTeamApiTeamsIdGetParams,) => [`/api/teams/id`, ...(params ? [params]: [])] as const;
  

    
export const getGetTeamApiTeamsIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getTeamApiTeamsIdGet>>, TError = AxiosError<HTTPValidationError>>(params: GetTeamApiTeamsIdGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTeamApiTeamsIdGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getTeamApiTeamsIdGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTeamApiTeamsIdGetQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTeamApiTeamsIdGet>>> = ({ signal }) => getTeamApiTeamsIdGet(params, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetTeamApiTeamsIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getTeamApiTeamsIdGet>>>
export type GetTeamApiTeamsIdGetQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Team
 */
export const useGetTeamApiTeamsIdGet = <TData = Awaited<ReturnType<typeof getTeamApiTeamsIdGet>>, TError = AxiosError<HTTPValidationError>>(
 params: GetTeamApiTeamsIdGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTeamApiTeamsIdGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTeamApiTeamsIdGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * @summary Add Question
 */
export const addQuestionApiTemplatesQuestionsPut = (
    addQuestionApiTemplatesQuestionsPutBody: AddQuestionApiTemplatesQuestionsPutBody,
    params: AddQuestionApiTemplatesQuestionsPutParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.put(
      `/api/templates/questions/`,
      addQuestionApiTemplatesQuestionsPutBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getAddQuestionApiTemplatesQuestionsPutMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addQuestionApiTemplatesQuestionsPut>>, TError,{data: AddQuestionApiTemplatesQuestionsPutBody;params: AddQuestionApiTemplatesQuestionsPutParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof addQuestionApiTemplatesQuestionsPut>>, TError,{data: AddQuestionApiTemplatesQuestionsPutBody;params: AddQuestionApiTemplatesQuestionsPutParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addQuestionApiTemplatesQuestionsPut>>, {data: AddQuestionApiTemplatesQuestionsPutBody;params: AddQuestionApiTemplatesQuestionsPutParams}> = (props) => {
          const {data,params} = props ?? {};

          return  addQuestionApiTemplatesQuestionsPut(data,params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type AddQuestionApiTemplatesQuestionsPutMutationResult = NonNullable<Awaited<ReturnType<typeof addQuestionApiTemplatesQuestionsPut>>>
    export type AddQuestionApiTemplatesQuestionsPutMutationBody = AddQuestionApiTemplatesQuestionsPutBody
    export type AddQuestionApiTemplatesQuestionsPutMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Add Question
 */
export const useAddQuestionApiTemplatesQuestionsPut = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addQuestionApiTemplatesQuestionsPut>>, TError,{data: AddQuestionApiTemplatesQuestionsPutBody;params: AddQuestionApiTemplatesQuestionsPutParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getAddQuestionApiTemplatesQuestionsPutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Remove Question
 */
export const removeQuestionApiTemplatesQuestionsDelete = (
    params: RemoveQuestionApiTemplatesQuestionsDeleteParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.delete(
      `/api/templates/questions/`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getRemoveQuestionApiTemplatesQuestionsDeleteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeQuestionApiTemplatesQuestionsDelete>>, TError,{params: RemoveQuestionApiTemplatesQuestionsDeleteParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof removeQuestionApiTemplatesQuestionsDelete>>, TError,{params: RemoveQuestionApiTemplatesQuestionsDeleteParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeQuestionApiTemplatesQuestionsDelete>>, {params: RemoveQuestionApiTemplatesQuestionsDeleteParams}> = (props) => {
          const {params} = props ?? {};

          return  removeQuestionApiTemplatesQuestionsDelete(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RemoveQuestionApiTemplatesQuestionsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof removeQuestionApiTemplatesQuestionsDelete>>>
    
    export type RemoveQuestionApiTemplatesQuestionsDeleteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Remove Question
 */
export const useRemoveQuestionApiTemplatesQuestionsDelete = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeQuestionApiTemplatesQuestionsDelete>>, TError,{params: RemoveQuestionApiTemplatesQuestionsDeleteParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getRemoveQuestionApiTemplatesQuestionsDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Modify Question
 */
export const modifyQuestionApiTemplatesQuestionsPatch = (
    modifyQuestionApiTemplatesQuestionsPatchBody: ModifyQuestionApiTemplatesQuestionsPatchBody,
    params: ModifyQuestionApiTemplatesQuestionsPatchParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.patch(
      `/api/templates/questions/`,
      modifyQuestionApiTemplatesQuestionsPatchBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getModifyQuestionApiTemplatesQuestionsPatchMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof modifyQuestionApiTemplatesQuestionsPatch>>, TError,{data: ModifyQuestionApiTemplatesQuestionsPatchBody;params: ModifyQuestionApiTemplatesQuestionsPatchParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof modifyQuestionApiTemplatesQuestionsPatch>>, TError,{data: ModifyQuestionApiTemplatesQuestionsPatchBody;params: ModifyQuestionApiTemplatesQuestionsPatchParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof modifyQuestionApiTemplatesQuestionsPatch>>, {data: ModifyQuestionApiTemplatesQuestionsPatchBody;params: ModifyQuestionApiTemplatesQuestionsPatchParams}> = (props) => {
          const {data,params} = props ?? {};

          return  modifyQuestionApiTemplatesQuestionsPatch(data,params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ModifyQuestionApiTemplatesQuestionsPatchMutationResult = NonNullable<Awaited<ReturnType<typeof modifyQuestionApiTemplatesQuestionsPatch>>>
    export type ModifyQuestionApiTemplatesQuestionsPatchMutationBody = ModifyQuestionApiTemplatesQuestionsPatchBody
    export type ModifyQuestionApiTemplatesQuestionsPatchMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Modify Question
 */
export const useModifyQuestionApiTemplatesQuestionsPatch = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof modifyQuestionApiTemplatesQuestionsPatch>>, TError,{data: ModifyQuestionApiTemplatesQuestionsPatchBody;params: ModifyQuestionApiTemplatesQuestionsPatchParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getModifyQuestionApiTemplatesQuestionsPatchMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Duplicate Question
 */
export const duplicateQuestionApiTemplatesQuestionsDuplicatePut = (
    params: DuplicateQuestionApiTemplatesQuestionsDuplicatePutParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.put(
      `/api/templates/questions/duplicate`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getDuplicateQuestionApiTemplatesQuestionsDuplicatePutMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof duplicateQuestionApiTemplatesQuestionsDuplicatePut>>, TError,{params: DuplicateQuestionApiTemplatesQuestionsDuplicatePutParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof duplicateQuestionApiTemplatesQuestionsDuplicatePut>>, TError,{params: DuplicateQuestionApiTemplatesQuestionsDuplicatePutParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof duplicateQuestionApiTemplatesQuestionsDuplicatePut>>, {params: DuplicateQuestionApiTemplatesQuestionsDuplicatePutParams}> = (props) => {
          const {params} = props ?? {};

          return  duplicateQuestionApiTemplatesQuestionsDuplicatePut(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DuplicateQuestionApiTemplatesQuestionsDuplicatePutMutationResult = NonNullable<Awaited<ReturnType<typeof duplicateQuestionApiTemplatesQuestionsDuplicatePut>>>
    
    export type DuplicateQuestionApiTemplatesQuestionsDuplicatePutMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Duplicate Question
 */
export const useDuplicateQuestionApiTemplatesQuestionsDuplicatePut = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof duplicateQuestionApiTemplatesQuestionsDuplicatePut>>, TError,{params: DuplicateQuestionApiTemplatesQuestionsDuplicatePutParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getDuplicateQuestionApiTemplatesQuestionsDuplicatePutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Elevate Question
 */
export const elevateQuestionApiTemplatesQuestionsElevatePost = (
    elevateQuestionApiTemplatesQuestionsElevatePostBody: ElevateQuestionApiTemplatesQuestionsElevatePostBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ElevateQuestionApiTemplatesQuestionsElevatePost200>> => {
    return axios.post(
      `/api/templates/questions/elevate`,
      elevateQuestionApiTemplatesQuestionsElevatePostBody,options
    );
  }



export const getElevateQuestionApiTemplatesQuestionsElevatePostMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof elevateQuestionApiTemplatesQuestionsElevatePost>>, TError,{data: ElevateQuestionApiTemplatesQuestionsElevatePostBody}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof elevateQuestionApiTemplatesQuestionsElevatePost>>, TError,{data: ElevateQuestionApiTemplatesQuestionsElevatePostBody}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof elevateQuestionApiTemplatesQuestionsElevatePost>>, {data: ElevateQuestionApiTemplatesQuestionsElevatePostBody}> = (props) => {
          const {data} = props ?? {};

          return  elevateQuestionApiTemplatesQuestionsElevatePost(data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ElevateQuestionApiTemplatesQuestionsElevatePostMutationResult = NonNullable<Awaited<ReturnType<typeof elevateQuestionApiTemplatesQuestionsElevatePost>>>
    export type ElevateQuestionApiTemplatesQuestionsElevatePostMutationBody = ElevateQuestionApiTemplatesQuestionsElevatePostBody
    export type ElevateQuestionApiTemplatesQuestionsElevatePostMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Elevate Question
 */
export const useElevateQuestionApiTemplatesQuestionsElevatePost = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof elevateQuestionApiTemplatesQuestionsElevatePost>>, TError,{data: ElevateQuestionApiTemplatesQuestionsElevatePostBody}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getElevateQuestionApiTemplatesQuestionsElevatePostMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Get Question
 */
export const getQuestionApiTemplatesQuestionsIdGet = (
    params: GetQuestionApiTemplatesQuestionsIdGetParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetQuestionApiTemplatesQuestionsIdGet200>> => {
    return axios.get(
      `/api/templates/questions/id`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetQuestionApiTemplatesQuestionsIdGetQueryKey = (params: GetQuestionApiTemplatesQuestionsIdGetParams,) => [`/api/templates/questions/id`, ...(params ? [params]: [])] as const;
  

    
export const getGetQuestionApiTemplatesQuestionsIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getQuestionApiTemplatesQuestionsIdGet>>, TError = AxiosError<HTTPValidationError>>(params: GetQuestionApiTemplatesQuestionsIdGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getQuestionApiTemplatesQuestionsIdGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getQuestionApiTemplatesQuestionsIdGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetQuestionApiTemplatesQuestionsIdGetQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getQuestionApiTemplatesQuestionsIdGet>>> = ({ signal }) => getQuestionApiTemplatesQuestionsIdGet(params, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetQuestionApiTemplatesQuestionsIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getQuestionApiTemplatesQuestionsIdGet>>>
export type GetQuestionApiTemplatesQuestionsIdGetQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Question
 */
export const useGetQuestionApiTemplatesQuestionsIdGet = <TData = Awaited<ReturnType<typeof getQuestionApiTemplatesQuestionsIdGet>>, TError = AxiosError<HTTPValidationError>>(
 params: GetQuestionApiTemplatesQuestionsIdGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getQuestionApiTemplatesQuestionsIdGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetQuestionApiTemplatesQuestionsIdGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * @summary Move Question
 */
export const moveQuestionApiTemplatesQuestionsMovePost = (
    params: MoveQuestionApiTemplatesQuestionsMovePostParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.post(
      `/api/templates/questions/move`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getMoveQuestionApiTemplatesQuestionsMovePostMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moveQuestionApiTemplatesQuestionsMovePost>>, TError,{params: MoveQuestionApiTemplatesQuestionsMovePostParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof moveQuestionApiTemplatesQuestionsMovePost>>, TError,{params: MoveQuestionApiTemplatesQuestionsMovePostParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof moveQuestionApiTemplatesQuestionsMovePost>>, {params: MoveQuestionApiTemplatesQuestionsMovePostParams}> = (props) => {
          const {params} = props ?? {};

          return  moveQuestionApiTemplatesQuestionsMovePost(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type MoveQuestionApiTemplatesQuestionsMovePostMutationResult = NonNullable<Awaited<ReturnType<typeof moveQuestionApiTemplatesQuestionsMovePost>>>
    
    export type MoveQuestionApiTemplatesQuestionsMovePostMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Move Question
 */
export const useMoveQuestionApiTemplatesQuestionsMovePost = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moveQuestionApiTemplatesQuestionsMovePost>>, TError,{params: MoveQuestionApiTemplatesQuestionsMovePostParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getMoveQuestionApiTemplatesQuestionsMovePostMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Set Questions
 */
export const setQuestionsApiTemplatesQuestionsAllPost = (
    setQuestionsApiTemplatesQuestionsAllPostBodyItem: SetQuestionsApiTemplatesQuestionsAllPostBodyItem[],
    params: SetQuestionsApiTemplatesQuestionsAllPostParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.post(
      `/api/templates/questions/all`,
      setQuestionsApiTemplatesQuestionsAllPostBodyItem,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getSetQuestionsApiTemplatesQuestionsAllPostMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setQuestionsApiTemplatesQuestionsAllPost>>, TError,{data: SetQuestionsApiTemplatesQuestionsAllPostBodyItem[];params: SetQuestionsApiTemplatesQuestionsAllPostParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof setQuestionsApiTemplatesQuestionsAllPost>>, TError,{data: SetQuestionsApiTemplatesQuestionsAllPostBodyItem[];params: SetQuestionsApiTemplatesQuestionsAllPostParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof setQuestionsApiTemplatesQuestionsAllPost>>, {data: SetQuestionsApiTemplatesQuestionsAllPostBodyItem[];params: SetQuestionsApiTemplatesQuestionsAllPostParams}> = (props) => {
          const {data,params} = props ?? {};

          return  setQuestionsApiTemplatesQuestionsAllPost(data,params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type SetQuestionsApiTemplatesQuestionsAllPostMutationResult = NonNullable<Awaited<ReturnType<typeof setQuestionsApiTemplatesQuestionsAllPost>>>
    export type SetQuestionsApiTemplatesQuestionsAllPostMutationBody = SetQuestionsApiTemplatesQuestionsAllPostBodyItem[]
    export type SetQuestionsApiTemplatesQuestionsAllPostMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Set Questions
 */
export const useSetQuestionsApiTemplatesQuestionsAllPost = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setQuestionsApiTemplatesQuestionsAllPost>>, TError,{data: SetQuestionsApiTemplatesQuestionsAllPostBodyItem[];params: SetQuestionsApiTemplatesQuestionsAllPostParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getSetQuestionsApiTemplatesQuestionsAllPostMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Get Quiz
 */
export const getQuizApiTemplatesGet = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Quiz[]>> => {
    return axios.get(
      `/api/templates/`,options
    );
  }


export const getGetQuizApiTemplatesGetQueryKey = () => [`/api/templates/`] as const;
  

    
export const getGetQuizApiTemplatesGetQueryOptions = <TData = Awaited<ReturnType<typeof getQuizApiTemplatesGet>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getQuizApiTemplatesGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getQuizApiTemplatesGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetQuizApiTemplatesGetQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getQuizApiTemplatesGet>>> = ({ signal }) => getQuizApiTemplatesGet({ signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetQuizApiTemplatesGetQueryResult = NonNullable<Awaited<ReturnType<typeof getQuizApiTemplatesGet>>>
export type GetQuizApiTemplatesGetQueryError = AxiosError<unknown>

/**
 * @summary Get Quiz
 */
export const useGetQuizApiTemplatesGet = <TData = Awaited<ReturnType<typeof getQuizApiTemplatesGet>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getQuizApiTemplatesGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetQuizApiTemplatesGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * @summary Create Quiz
 */
export const createQuizApiTemplatesPut = (
    quiz: Quiz, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Quiz>> => {
    return axios.put(
      `/api/templates/`,
      quiz,options
    );
  }



export const getCreateQuizApiTemplatesPutMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createQuizApiTemplatesPut>>, TError,{data: Quiz}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createQuizApiTemplatesPut>>, TError,{data: Quiz}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createQuizApiTemplatesPut>>, {data: Quiz}> = (props) => {
          const {data} = props ?? {};

          return  createQuizApiTemplatesPut(data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateQuizApiTemplatesPutMutationResult = NonNullable<Awaited<ReturnType<typeof createQuizApiTemplatesPut>>>
    export type CreateQuizApiTemplatesPutMutationBody = Quiz
    export type CreateQuizApiTemplatesPutMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Quiz
 */
export const useCreateQuizApiTemplatesPut = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createQuizApiTemplatesPut>>, TError,{data: Quiz}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getCreateQuizApiTemplatesPutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Remove Quiz
 */
export const removeQuizApiTemplatesDelete = (
    params: RemoveQuizApiTemplatesDeleteParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.delete(
      `/api/templates/`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getRemoveQuizApiTemplatesDeleteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeQuizApiTemplatesDelete>>, TError,{params: RemoveQuizApiTemplatesDeleteParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof removeQuizApiTemplatesDelete>>, TError,{params: RemoveQuizApiTemplatesDeleteParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeQuizApiTemplatesDelete>>, {params: RemoveQuizApiTemplatesDeleteParams}> = (props) => {
          const {params} = props ?? {};

          return  removeQuizApiTemplatesDelete(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RemoveQuizApiTemplatesDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof removeQuizApiTemplatesDelete>>>
    
    export type RemoveQuizApiTemplatesDeleteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Remove Quiz
 */
export const useRemoveQuizApiTemplatesDelete = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeQuizApiTemplatesDelete>>, TError,{params: RemoveQuizApiTemplatesDeleteParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getRemoveQuizApiTemplatesDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Rename Quiz
 */
export const renameQuizApiTemplatesPatch = (
    params: RenameQuizApiTemplatesPatchParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Quiz>> => {
    return axios.patch(
      `/api/templates/`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getRenameQuizApiTemplatesPatchMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameQuizApiTemplatesPatch>>, TError,{params: RenameQuizApiTemplatesPatchParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof renameQuizApiTemplatesPatch>>, TError,{params: RenameQuizApiTemplatesPatchParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof renameQuizApiTemplatesPatch>>, {params: RenameQuizApiTemplatesPatchParams}> = (props) => {
          const {params} = props ?? {};

          return  renameQuizApiTemplatesPatch(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RenameQuizApiTemplatesPatchMutationResult = NonNullable<Awaited<ReturnType<typeof renameQuizApiTemplatesPatch>>>
    
    export type RenameQuizApiTemplatesPatchMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Rename Quiz
 */
export const useRenameQuizApiTemplatesPatch = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameQuizApiTemplatesPatch>>, TError,{params: RenameQuizApiTemplatesPatchParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getRenameQuizApiTemplatesPatchMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Duplicate Quiz
 */
export const duplicateQuizApiTemplatesDuplicatePut = (
    params: DuplicateQuizApiTemplatesDuplicatePutParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.put(
      `/api/templates/duplicate`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getDuplicateQuizApiTemplatesDuplicatePutMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof duplicateQuizApiTemplatesDuplicatePut>>, TError,{params: DuplicateQuizApiTemplatesDuplicatePutParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof duplicateQuizApiTemplatesDuplicatePut>>, TError,{params: DuplicateQuizApiTemplatesDuplicatePutParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof duplicateQuizApiTemplatesDuplicatePut>>, {params: DuplicateQuizApiTemplatesDuplicatePutParams}> = (props) => {
          const {params} = props ?? {};

          return  duplicateQuizApiTemplatesDuplicatePut(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DuplicateQuizApiTemplatesDuplicatePutMutationResult = NonNullable<Awaited<ReturnType<typeof duplicateQuizApiTemplatesDuplicatePut>>>
    
    export type DuplicateQuizApiTemplatesDuplicatePutMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Duplicate Quiz
 */
export const useDuplicateQuizApiTemplatesDuplicatePut = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof duplicateQuizApiTemplatesDuplicatePut>>, TError,{params: DuplicateQuizApiTemplatesDuplicatePutParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getDuplicateQuizApiTemplatesDuplicatePutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Get Quiz
 */
export const getQuizApiTemplatesIdGet = (
    params: GetQuizApiTemplatesIdGetParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Quiz>> => {
    return axios.get(
      `/api/templates/id`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetQuizApiTemplatesIdGetQueryKey = (params: GetQuizApiTemplatesIdGetParams,) => [`/api/templates/id`, ...(params ? [params]: [])] as const;
  

    
export const getGetQuizApiTemplatesIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getQuizApiTemplatesIdGet>>, TError = AxiosError<HTTPValidationError>>(params: GetQuizApiTemplatesIdGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getQuizApiTemplatesIdGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getQuizApiTemplatesIdGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetQuizApiTemplatesIdGetQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getQuizApiTemplatesIdGet>>> = ({ signal }) => getQuizApiTemplatesIdGet(params, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetQuizApiTemplatesIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getQuizApiTemplatesIdGet>>>
export type GetQuizApiTemplatesIdGetQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Quiz
 */
export const useGetQuizApiTemplatesIdGet = <TData = Awaited<ReturnType<typeof getQuizApiTemplatesIdGet>>, TError = AxiosError<HTTPValidationError>>(
 params: GetQuizApiTemplatesIdGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getQuizApiTemplatesIdGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetQuizApiTemplatesIdGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * @summary Get Instances
 */
export const getInstancesApiInstanceGet = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<QuizInstance[]>> => {
    return axios.get(
      `/api/instance/`,options
    );
  }


export const getGetInstancesApiInstanceGetQueryKey = () => [`/api/instance/`] as const;
  

    
export const getGetInstancesApiInstanceGetQueryOptions = <TData = Awaited<ReturnType<typeof getInstancesApiInstanceGet>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInstancesApiInstanceGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getInstancesApiInstanceGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInstancesApiInstanceGetQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInstancesApiInstanceGet>>> = ({ signal }) => getInstancesApiInstanceGet({ signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetInstancesApiInstanceGetQueryResult = NonNullable<Awaited<ReturnType<typeof getInstancesApiInstanceGet>>>
export type GetInstancesApiInstanceGetQueryError = AxiosError<unknown>

/**
 * @summary Get Instances
 */
export const useGetInstancesApiInstanceGet = <TData = Awaited<ReturnType<typeof getInstancesApiInstanceGet>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInstancesApiInstanceGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetInstancesApiInstanceGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * @summary Remove Instance
 */
export const removeInstanceApiInstanceDelete = (
    params: RemoveInstanceApiInstanceDeleteParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.delete(
      `/api/instance/`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getRemoveInstanceApiInstanceDeleteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeInstanceApiInstanceDelete>>, TError,{params: RemoveInstanceApiInstanceDeleteParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof removeInstanceApiInstanceDelete>>, TError,{params: RemoveInstanceApiInstanceDeleteParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeInstanceApiInstanceDelete>>, {params: RemoveInstanceApiInstanceDeleteParams}> = (props) => {
          const {params} = props ?? {};

          return  removeInstanceApiInstanceDelete(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RemoveInstanceApiInstanceDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof removeInstanceApiInstanceDelete>>>
    
    export type RemoveInstanceApiInstanceDeleteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Remove Instance
 */
export const useRemoveInstanceApiInstanceDelete = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeInstanceApiInstanceDelete>>, TError,{params: RemoveInstanceApiInstanceDeleteParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getRemoveInstanceApiInstanceDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Rename Instance
 */
export const renameInstanceApiInstancePatch = (
    params: RenameInstanceApiInstancePatchParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<QuizInstance>> => {
    return axios.patch(
      `/api/instance/`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getRenameInstanceApiInstancePatchMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameInstanceApiInstancePatch>>, TError,{params: RenameInstanceApiInstancePatchParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof renameInstanceApiInstancePatch>>, TError,{params: RenameInstanceApiInstancePatchParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof renameInstanceApiInstancePatch>>, {params: RenameInstanceApiInstancePatchParams}> = (props) => {
          const {params} = props ?? {};

          return  renameInstanceApiInstancePatch(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RenameInstanceApiInstancePatchMutationResult = NonNullable<Awaited<ReturnType<typeof renameInstanceApiInstancePatch>>>
    
    export type RenameInstanceApiInstancePatchMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Rename Instance
 */
export const useRenameInstanceApiInstancePatch = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameInstanceApiInstancePatch>>, TError,{params: RenameInstanceApiInstancePatchParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getRenameInstanceApiInstancePatchMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Set Activation
 */
export const setActivationApiInstanceActivatePatch = (
    params: SetActivationApiInstanceActivatePatchParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.patch(
      `/api/instance/activate`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getSetActivationApiInstanceActivatePatchMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setActivationApiInstanceActivatePatch>>, TError,{params: SetActivationApiInstanceActivatePatchParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof setActivationApiInstanceActivatePatch>>, TError,{params: SetActivationApiInstanceActivatePatchParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof setActivationApiInstanceActivatePatch>>, {params: SetActivationApiInstanceActivatePatchParams}> = (props) => {
          const {params} = props ?? {};

          return  setActivationApiInstanceActivatePatch(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type SetActivationApiInstanceActivatePatchMutationResult = NonNullable<Awaited<ReturnType<typeof setActivationApiInstanceActivatePatch>>>
    
    export type SetActivationApiInstanceActivatePatchMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Set Activation
 */
export const useSetActivationApiInstanceActivatePatch = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setActivationApiInstanceActivatePatch>>, TError,{params: SetActivationApiInstanceActivatePatchParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getSetActivationApiInstanceActivatePatchMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Activate Question
 */
export const activateQuestionApiInstanceQuestionPatch = (
    params: ActivateQuestionApiInstanceQuestionPatchParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.patch(
      `/api/instance/question`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getActivateQuestionApiInstanceQuestionPatchMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof activateQuestionApiInstanceQuestionPatch>>, TError,{params: ActivateQuestionApiInstanceQuestionPatchParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof activateQuestionApiInstanceQuestionPatch>>, TError,{params: ActivateQuestionApiInstanceQuestionPatchParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof activateQuestionApiInstanceQuestionPatch>>, {params: ActivateQuestionApiInstanceQuestionPatchParams}> = (props) => {
          const {params} = props ?? {};

          return  activateQuestionApiInstanceQuestionPatch(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ActivateQuestionApiInstanceQuestionPatchMutationResult = NonNullable<Awaited<ReturnType<typeof activateQuestionApiInstanceQuestionPatch>>>
    
    export type ActivateQuestionApiInstanceQuestionPatchMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Activate Question
 */
export const useActivateQuestionApiInstanceQuestionPatch = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof activateQuestionApiInstanceQuestionPatch>>, TError,{params: ActivateQuestionApiInstanceQuestionPatchParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getActivateQuestionApiInstanceQuestionPatchMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Skip Gtd Definition
 */
export const skipGtdDefinitionApiInstanceGtdSkipteamPatch = (
    params: SkipGtdDefinitionApiInstanceGtdSkipteamPatchParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.patch(
      `/api/instance/gtd/skipteam`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getSkipGtdDefinitionApiInstanceGtdSkipteamPatchMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof skipGtdDefinitionApiInstanceGtdSkipteamPatch>>, TError,{params: SkipGtdDefinitionApiInstanceGtdSkipteamPatchParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof skipGtdDefinitionApiInstanceGtdSkipteamPatch>>, TError,{params: SkipGtdDefinitionApiInstanceGtdSkipteamPatchParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof skipGtdDefinitionApiInstanceGtdSkipteamPatch>>, {params: SkipGtdDefinitionApiInstanceGtdSkipteamPatchParams}> = (props) => {
          const {params} = props ?? {};

          return  skipGtdDefinitionApiInstanceGtdSkipteamPatch(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type SkipGtdDefinitionApiInstanceGtdSkipteamPatchMutationResult = NonNullable<Awaited<ReturnType<typeof skipGtdDefinitionApiInstanceGtdSkipteamPatch>>>
    
    export type SkipGtdDefinitionApiInstanceGtdSkipteamPatchMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Skip Gtd Definition
 */
export const useSkipGtdDefinitionApiInstanceGtdSkipteamPatch = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof skipGtdDefinitionApiInstanceGtdSkipteamPatch>>, TError,{params: SkipGtdDefinitionApiInstanceGtdSkipteamPatchParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getSkipGtdDefinitionApiInstanceGtdSkipteamPatchMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Activate Gtd Question
 */
export const activateGtdQuestionApiInstanceGtdQuestionPatch = (
    params: ActivateGtdQuestionApiInstanceGtdQuestionPatchParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.patch(
      `/api/instance/gtd/question`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getActivateGtdQuestionApiInstanceGtdQuestionPatchMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof activateGtdQuestionApiInstanceGtdQuestionPatch>>, TError,{params: ActivateGtdQuestionApiInstanceGtdQuestionPatchParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof activateGtdQuestionApiInstanceGtdQuestionPatch>>, TError,{params: ActivateGtdQuestionApiInstanceGtdQuestionPatchParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof activateGtdQuestionApiInstanceGtdQuestionPatch>>, {params: ActivateGtdQuestionApiInstanceGtdQuestionPatchParams}> = (props) => {
          const {params} = props ?? {};

          return  activateGtdQuestionApiInstanceGtdQuestionPatch(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ActivateGtdQuestionApiInstanceGtdQuestionPatchMutationResult = NonNullable<Awaited<ReturnType<typeof activateGtdQuestionApiInstanceGtdQuestionPatch>>>
    
    export type ActivateGtdQuestionApiInstanceGtdQuestionPatchMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Activate Gtd Question
 */
export const useActivateGtdQuestionApiInstanceGtdQuestionPatch = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof activateGtdQuestionApiInstanceGtdQuestionPatch>>, TError,{params: ActivateGtdQuestionApiInstanceGtdQuestionPatchParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getActivateGtdQuestionApiInstanceGtdQuestionPatchMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Move
 */
export const moveApiInstanceMovePatch = (
    params: MoveApiInstanceMovePatchParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.patch(
      `/api/instance/move`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getMoveApiInstanceMovePatchMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moveApiInstanceMovePatch>>, TError,{params: MoveApiInstanceMovePatchParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof moveApiInstanceMovePatch>>, TError,{params: MoveApiInstanceMovePatchParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof moveApiInstanceMovePatch>>, {params: MoveApiInstanceMovePatchParams}> = (props) => {
          const {params} = props ?? {};

          return  moveApiInstanceMovePatch(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type MoveApiInstanceMovePatchMutationResult = NonNullable<Awaited<ReturnType<typeof moveApiInstanceMovePatch>>>
    
    export type MoveApiInstanceMovePatchMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Move
 */
export const useMoveApiInstanceMovePatch = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moveApiInstanceMovePatch>>, TError,{params: MoveApiInstanceMovePatchParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getMoveApiInstanceMovePatchMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Get Quiz
 */
export const getQuizApiInstanceIdGet = (
    params: GetQuizApiInstanceIdGetParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<QuizInstance>> => {
    return axios.get(
      `/api/instance/id`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetQuizApiInstanceIdGetQueryKey = (params: GetQuizApiInstanceIdGetParams,) => [`/api/instance/id`, ...(params ? [params]: [])] as const;
  

    
export const getGetQuizApiInstanceIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getQuizApiInstanceIdGet>>, TError = AxiosError<HTTPValidationError>>(params: GetQuizApiInstanceIdGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getQuizApiInstanceIdGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getQuizApiInstanceIdGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetQuizApiInstanceIdGetQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getQuizApiInstanceIdGet>>> = ({ signal }) => getQuizApiInstanceIdGet(params, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetQuizApiInstanceIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getQuizApiInstanceIdGet>>>
export type GetQuizApiInstanceIdGetQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Quiz
 */
export const useGetQuizApiInstanceIdGet = <TData = Awaited<ReturnType<typeof getQuizApiInstanceIdGet>>, TError = AxiosError<HTTPValidationError>>(
 params: GetQuizApiInstanceIdGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getQuizApiInstanceIdGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetQuizApiInstanceIdGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * @summary Set Backbutton Visisble
 */
export const setBackbuttonVisisbleApiInstanceBackbuttonPut = (
    params: SetBackbuttonVisisbleApiInstanceBackbuttonPutParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.put(
      `/api/instance/backbutton`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getSetBackbuttonVisisbleApiInstanceBackbuttonPutMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setBackbuttonVisisbleApiInstanceBackbuttonPut>>, TError,{params: SetBackbuttonVisisbleApiInstanceBackbuttonPutParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof setBackbuttonVisisbleApiInstanceBackbuttonPut>>, TError,{params: SetBackbuttonVisisbleApiInstanceBackbuttonPutParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof setBackbuttonVisisbleApiInstanceBackbuttonPut>>, {params: SetBackbuttonVisisbleApiInstanceBackbuttonPutParams}> = (props) => {
          const {params} = props ?? {};

          return  setBackbuttonVisisbleApiInstanceBackbuttonPut(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type SetBackbuttonVisisbleApiInstanceBackbuttonPutMutationResult = NonNullable<Awaited<ReturnType<typeof setBackbuttonVisisbleApiInstanceBackbuttonPut>>>
    
    export type SetBackbuttonVisisbleApiInstanceBackbuttonPutMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Set Backbutton Visisble
 */
export const useSetBackbuttonVisisbleApiInstanceBackbuttonPut = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setBackbuttonVisisbleApiInstanceBackbuttonPut>>, TError,{params: SetBackbuttonVisisbleApiInstanceBackbuttonPutParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getSetBackbuttonVisisbleApiInstanceBackbuttonPutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Create Instance
 */
export const createInstanceApiInstanceCreatePut = (
    params: CreateInstanceApiInstanceCreatePutParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<QuizInstance>> => {
    return axios.put(
      `/api/instance/create`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getCreateInstanceApiInstanceCreatePutMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createInstanceApiInstanceCreatePut>>, TError,{params: CreateInstanceApiInstanceCreatePutParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createInstanceApiInstanceCreatePut>>, TError,{params: CreateInstanceApiInstanceCreatePutParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createInstanceApiInstanceCreatePut>>, {params: CreateInstanceApiInstanceCreatePutParams}> = (props) => {
          const {params} = props ?? {};

          return  createInstanceApiInstanceCreatePut(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateInstanceApiInstanceCreatePutMutationResult = NonNullable<Awaited<ReturnType<typeof createInstanceApiInstanceCreatePut>>>
    
    export type CreateInstanceApiInstanceCreatePutMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Instance
 */
export const useCreateInstanceApiInstanceCreatePut = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createInstanceApiInstanceCreatePut>>, TError,{params: CreateInstanceApiInstanceCreatePutParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getCreateInstanceApiInstanceCreatePutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Give Answer String
 */
export const giveAnswerStringApiQuizStringPut = (
    giveAnswerStringApiQuizStringPutBody: string[],
    params: GiveAnswerStringApiQuizStringPutParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.put(
      `/api/quiz/string`,
      giveAnswerStringApiQuizStringPutBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getGiveAnswerStringApiQuizStringPutMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof giveAnswerStringApiQuizStringPut>>, TError,{data: string[];params: GiveAnswerStringApiQuizStringPutParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof giveAnswerStringApiQuizStringPut>>, TError,{data: string[];params: GiveAnswerStringApiQuizStringPutParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof giveAnswerStringApiQuizStringPut>>, {data: string[];params: GiveAnswerStringApiQuizStringPutParams}> = (props) => {
          const {data,params} = props ?? {};

          return  giveAnswerStringApiQuizStringPut(data,params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type GiveAnswerStringApiQuizStringPutMutationResult = NonNullable<Awaited<ReturnType<typeof giveAnswerStringApiQuizStringPut>>>
    export type GiveAnswerStringApiQuizStringPutMutationBody = string[]
    export type GiveAnswerStringApiQuizStringPutMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Give Answer String
 */
export const useGiveAnswerStringApiQuizStringPut = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof giveAnswerStringApiQuizStringPut>>, TError,{data: string[];params: GiveAnswerStringApiQuizStringPutParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getGiveAnswerStringApiQuizStringPutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Get Gtd Definitions
 */
export const getGtdDefinitionsApiQuizGtdDefinitionGet = (
    params: GetGtdDefinitionsApiQuizGtdDefinitionGetParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string[]>> => {
    return axios.get(
      `/api/quiz/gtd/definition`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetGtdDefinitionsApiQuizGtdDefinitionGetQueryKey = (params: GetGtdDefinitionsApiQuizGtdDefinitionGetParams,) => [`/api/quiz/gtd/definition`, ...(params ? [params]: [])] as const;
  

    
export const getGetGtdDefinitionsApiQuizGtdDefinitionGetQueryOptions = <TData = Awaited<ReturnType<typeof getGtdDefinitionsApiQuizGtdDefinitionGet>>, TError = AxiosError<HTTPValidationError>>(params: GetGtdDefinitionsApiQuizGtdDefinitionGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getGtdDefinitionsApiQuizGtdDefinitionGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getGtdDefinitionsApiQuizGtdDefinitionGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetGtdDefinitionsApiQuizGtdDefinitionGetQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getGtdDefinitionsApiQuizGtdDefinitionGet>>> = ({ signal }) => getGtdDefinitionsApiQuizGtdDefinitionGet(params, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetGtdDefinitionsApiQuizGtdDefinitionGetQueryResult = NonNullable<Awaited<ReturnType<typeof getGtdDefinitionsApiQuizGtdDefinitionGet>>>
export type GetGtdDefinitionsApiQuizGtdDefinitionGetQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Gtd Definitions
 */
export const useGetGtdDefinitionsApiQuizGtdDefinitionGet = <TData = Awaited<ReturnType<typeof getGtdDefinitionsApiQuizGtdDefinitionGet>>, TError = AxiosError<HTTPValidationError>>(
 params: GetGtdDefinitionsApiQuizGtdDefinitionGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getGtdDefinitionsApiQuizGtdDefinitionGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetGtdDefinitionsApiQuizGtdDefinitionGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * @summary Give Gtd Definition
 */
export const giveGtdDefinitionApiQuizGtdDefinitionPut = (
    params: GiveGtdDefinitionApiQuizGtdDefinitionPutParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.put(
      `/api/quiz/gtd/definition`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getGiveGtdDefinitionApiQuizGtdDefinitionPutMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof giveGtdDefinitionApiQuizGtdDefinitionPut>>, TError,{params: GiveGtdDefinitionApiQuizGtdDefinitionPutParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof giveGtdDefinitionApiQuizGtdDefinitionPut>>, TError,{params: GiveGtdDefinitionApiQuizGtdDefinitionPutParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof giveGtdDefinitionApiQuizGtdDefinitionPut>>, {params: GiveGtdDefinitionApiQuizGtdDefinitionPutParams}> = (props) => {
          const {params} = props ?? {};

          return  giveGtdDefinitionApiQuizGtdDefinitionPut(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type GiveGtdDefinitionApiQuizGtdDefinitionPutMutationResult = NonNullable<Awaited<ReturnType<typeof giveGtdDefinitionApiQuizGtdDefinitionPut>>>
    
    export type GiveGtdDefinitionApiQuizGtdDefinitionPutMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Give Gtd Definition
 */
export const useGiveGtdDefinitionApiQuizGtdDefinitionPut = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof giveGtdDefinitionApiQuizGtdDefinitionPut>>, TError,{params: GiveGtdDefinitionApiQuizGtdDefinitionPutParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getGiveGtdDefinitionApiQuizGtdDefinitionPutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Give Gtd Answer
 */
export const giveGtdAnswerApiQuizGtdAnswerPut = (
    params: GiveGtdAnswerApiQuizGtdAnswerPutParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.put(
      `/api/quiz/gtd/answer`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getGiveGtdAnswerApiQuizGtdAnswerPutMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof giveGtdAnswerApiQuizGtdAnswerPut>>, TError,{params: GiveGtdAnswerApiQuizGtdAnswerPutParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof giveGtdAnswerApiQuizGtdAnswerPut>>, TError,{params: GiveGtdAnswerApiQuizGtdAnswerPutParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof giveGtdAnswerApiQuizGtdAnswerPut>>, {params: GiveGtdAnswerApiQuizGtdAnswerPutParams}> = (props) => {
          const {params} = props ?? {};

          return  giveGtdAnswerApiQuizGtdAnswerPut(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type GiveGtdAnswerApiQuizGtdAnswerPutMutationResult = NonNullable<Awaited<ReturnType<typeof giveGtdAnswerApiQuizGtdAnswerPut>>>
    
    export type GiveGtdAnswerApiQuizGtdAnswerPutMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Give Gtd Answer
 */
export const useGiveGtdAnswerApiQuizGtdAnswerPut = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof giveGtdAnswerApiQuizGtdAnswerPut>>, TError,{params: GiveGtdAnswerApiQuizGtdAnswerPutParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getGiveGtdAnswerApiQuizGtdAnswerPutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Submit
 */
export const submitApiQuizSubmitPut = (
    params: SubmitApiQuizSubmitPutParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.put(
      `/api/quiz/submit`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getSubmitApiQuizSubmitPutMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof submitApiQuizSubmitPut>>, TError,{params: SubmitApiQuizSubmitPutParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof submitApiQuizSubmitPut>>, TError,{params: SubmitApiQuizSubmitPutParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof submitApiQuizSubmitPut>>, {params: SubmitApiQuizSubmitPutParams}> = (props) => {
          const {params} = props ?? {};

          return  submitApiQuizSubmitPut(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type SubmitApiQuizSubmitPutMutationResult = NonNullable<Awaited<ReturnType<typeof submitApiQuizSubmitPut>>>
    
    export type SubmitApiQuizSubmitPutMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Submit
 */
export const useSubmitApiQuizSubmitPut = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof submitApiQuizSubmitPut>>, TError,{params: SubmitApiQuizSubmitPutParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getSubmitApiQuizSubmitPutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Unsubmit
 */
export const unsubmitApiQuizUnsubmitPut = (
    params: UnsubmitApiQuizUnsubmitPutParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.put(
      `/api/quiz/unsubmit`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getUnsubmitApiQuizUnsubmitPutMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unsubmitApiQuizUnsubmitPut>>, TError,{params: UnsubmitApiQuizUnsubmitPutParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof unsubmitApiQuizUnsubmitPut>>, TError,{params: UnsubmitApiQuizUnsubmitPutParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof unsubmitApiQuizUnsubmitPut>>, {params: UnsubmitApiQuizUnsubmitPutParams}> = (props) => {
          const {params} = props ?? {};

          return  unsubmitApiQuizUnsubmitPut(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UnsubmitApiQuizUnsubmitPutMutationResult = NonNullable<Awaited<ReturnType<typeof unsubmitApiQuizUnsubmitPut>>>
    
    export type UnsubmitApiQuizUnsubmitPutMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Unsubmit
 */
export const useUnsubmitApiQuizUnsubmitPut = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unsubmitApiQuizUnsubmitPut>>, TError,{params: UnsubmitApiQuizUnsubmitPutParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getUnsubmitApiQuizUnsubmitPutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Get Quiz Question
 */
export const getQuizQuestionApiQuizGet = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetQuizQuestionApiQuizGet200>> => {
    return axios.get(
      `/api/quiz/`,options
    );
  }


export const getGetQuizQuestionApiQuizGetQueryKey = () => [`/api/quiz/`] as const;
  

    
export const getGetQuizQuestionApiQuizGetQueryOptions = <TData = Awaited<ReturnType<typeof getQuizQuestionApiQuizGet>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getQuizQuestionApiQuizGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getQuizQuestionApiQuizGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetQuizQuestionApiQuizGetQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getQuizQuestionApiQuizGet>>> = ({ signal }) => getQuizQuestionApiQuizGet({ signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetQuizQuestionApiQuizGetQueryResult = NonNullable<Awaited<ReturnType<typeof getQuizQuestionApiQuizGet>>>
export type GetQuizQuestionApiQuizGetQueryError = AxiosError<unknown>

/**
 * @summary Get Quiz Question
 */
export const useGetQuizQuestionApiQuizGet = <TData = Awaited<ReturnType<typeof getQuizQuestionApiQuizGet>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getQuizQuestionApiQuizGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetQuizQuestionApiQuizGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * @summary Ping
 */
export const pingApiQuizPingPut = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string[]>> => {
    return axios.put(
      `/api/quiz/ping`,undefined,options
    );
  }



export const getPingApiQuizPingPutMutationOptions = <TError = AxiosError<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof pingApiQuizPingPut>>, TError,TVariables, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof pingApiQuizPingPut>>, TError,TVariables, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof pingApiQuizPingPut>>, TVariables> = () => {
          

          return  pingApiQuizPingPut(axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PingApiQuizPingPutMutationResult = NonNullable<Awaited<ReturnType<typeof pingApiQuizPingPut>>>
    
    export type PingApiQuizPingPutMutationError = AxiosError<unknown>

    /**
 * @summary Ping
 */
export const usePingApiQuizPingPut = <TError = AxiosError<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof pingApiQuizPingPut>>, TError,TVariables, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getPingApiQuizPingPutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Refresh All Clients
 */
export const refreshAllClientsApiRefreshAllPut = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.put(
      `/api/refresh_all`,undefined,options
    );
  }



export const getRefreshAllClientsApiRefreshAllPutMutationOptions = <TError = AxiosError<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof refreshAllClientsApiRefreshAllPut>>, TError,TVariables, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof refreshAllClientsApiRefreshAllPut>>, TError,TVariables, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof refreshAllClientsApiRefreshAllPut>>, TVariables> = () => {
          

          return  refreshAllClientsApiRefreshAllPut(axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RefreshAllClientsApiRefreshAllPutMutationResult = NonNullable<Awaited<ReturnType<typeof refreshAllClientsApiRefreshAllPut>>>
    
    export type RefreshAllClientsApiRefreshAllPutMutationError = AxiosError<unknown>

    /**
 * @summary Refresh All Clients
 */
export const useRefreshAllClientsApiRefreshAllPut = <TError = AxiosError<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof refreshAllClientsApiRefreshAllPut>>, TError,TVariables, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getRefreshAllClientsApiRefreshAllPutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Upload
 */
export const uploadDataUploadPost = (
    bodyUploadDataUploadPost: BodyUploadDataUploadPost, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {const formData = new FormData();
formData.append('file', bodyUploadDataUploadPost.file)

    return axios.post(
      `/data/upload`,
      formData,options
    );
  }



export const getUploadDataUploadPostMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadDataUploadPost>>, TError,{data: BodyUploadDataUploadPost}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof uploadDataUploadPost>>, TError,{data: BodyUploadDataUploadPost}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadDataUploadPost>>, {data: BodyUploadDataUploadPost}> = (props) => {
          const {data} = props ?? {};

          return  uploadDataUploadPost(data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UploadDataUploadPostMutationResult = NonNullable<Awaited<ReturnType<typeof uploadDataUploadPost>>>
    export type UploadDataUploadPostMutationBody = BodyUploadDataUploadPost
    export type UploadDataUploadPostMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Upload
 */
export const useUploadDataUploadPost = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadDataUploadPost>>, TError,{data: BodyUploadDataUploadPost}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getUploadDataUploadPostMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    