import {Button, Heading, Stack} from "@chakra-ui/react";
import React, {useContext, useEffect} from "react";
import {useGetTeamsApiTeamsGet} from "../Client/default/default";
import {DisplaySize, Team} from "../Client/Models";
import {useNavigate} from "react-router-dom";
import {LayoutContext} from "../Layout";
import {setTeam} from "../Store/Features/TeamReducer";
import {useAppDispatch} from "../Store/Hooks";

const TeamSelector = () => {
    const {size, setSize} = useContext(LayoutContext);
    const {isLoading, isError, data: teams} = useGetTeamsApiTeamsGet();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        setSize(DisplaySize.NORMAL)
    }, [setSize]);

    return (
        <Stack>
            <Heading as='h3' size='lg' mb={5}>
                Selecteer jouw team
            </Heading>
            {!isLoading && teams &&
                teams.data.map((d: Team, i) =>
                    <Button
                        key={`team-${i}`}
                        onClick={() => {
                            dispatch(setTeam(d));
                            navigate('/quiz');
                        }}>
                        {d.name}
                    </Button>
                )
            }
            <Button
                mt={5}
                variant={'outline'}
                onClick={() => navigate('/spectator')}>
                Meekijken zonder team
            </Button>
        </Stack>
    )
}

export {
    TeamSelector
}
