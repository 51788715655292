import {createSlice} from "@reduxjs/toolkit";

interface FontState {
    size: number
}

const initialState: FontState = {
    size: 20
};

const fontSlice = createSlice({
    name: 'font',
    initialState,
    reducers: {
        increaseFontSize: (state) => {
            return {
                ...state,
                size : state.size + 1
            }
        },
        decreaseFontSize: (state) => {
            return {
                ...state,
                size : state.size - 1
            }
        },
    },
})

export const {increaseFontSize, decreaseFontSize} = fontSlice.actions

export default fontSlice.reducer
