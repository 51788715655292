import {
    useGetQuizQuestionApiQuizGet,
} from "../../Client/default/default";
import React, {useContext, useEffect} from "react";
import {Heading} from "@chakra-ui/react";
import {EmptyMessage} from "../Utils/EmptyMessage";
import Loading from "../Utils/Loading";
import {AnimateMount} from "../Utils/Animation";
import {QuizInstanceQuestionsItem} from "../../Client/Models";
import {discriminateQuestionInstance} from "../Utils/QuestionDiscriminator";
import {
    GTDQuestionViewer,
    MultipleAnswerQuestionViewer,
    MultipleChoiceQuestionViewer,
    OpenQuestionViewer,
    QuestionMarkdownViewer
} from "./QuestionViewer";
import {LayoutContext} from "../../Layout";
import {WaitingForQuiz} from "./WaitingForQuiz";
import {useAppSelector} from "../../Store/Hooks";

const Quiz = () => {
    const { setSize } = useContext(LayoutContext);
    const team = useAppSelector((state) => state.team.team);
    let instance : QuizInstanceQuestionsItem | undefined = undefined;

    const {isLoading: isLoadingQuestion, isError: isErrorQuestion, data: questionData} = useGetQuizQuestionApiQuizGet();

    useEffect(() => {
        if (instance !== undefined) {
            setSize(instance.question.displaySize);
        }
    }, [setSize, instance, questionData]);

    if (isLoadingQuestion) {
        return <Loading/>
    }
    if (!team || team._id === "" || !questionData) {
        return <EmptyMessage text={"Error fetching data"}/>
    }

    if (questionData.status === 204) {
        return (
            <WaitingForQuiz/>
        )
    }

    instance = questionData.data! as QuizInstanceQuestionsItem;

    return (
        <AnimateMount customKey={instance._id!}>
            {
                discriminateQuestionInstance<React.ReactElement | null>(
                    instance,
                    {
                        Blank : q => (<QuestionMarkdownViewer markdown={q.question.question}/>),
                        Open : q => (<OpenQuestionViewer instance={q} team={team}/>),
                        MultipleChoice : q => (<MultipleChoiceQuestionViewer instance={q} team={team}/>),
                        MultipleAnswer :  q => (<MultipleAnswerQuestionViewer instance={q} team={team}/>),
                        GuessTheDefinition : q => (<GTDQuestionViewer instance={q} team={team}/>),
                        default : q => (<Heading>Dit type vraag is nog onder ontwikkeling...</Heading>)
                    }
                )
            }
        </AnimateMount>
    )
}
export {
    Quiz
}
