import {Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay} from "@chakra-ui/react";
import {QuizInstanceQuestionsItem, QuizQuestionsItem} from "../../Client/Models";
import {
    elevateQuestionApiTemplatesQuestionsElevatePost,
} from "../../Client/default/default";
import Loading from "../Utils/Loading";
import React, {useEffect, useState} from "react";
import {SpectateQuestionInstance} from "./Spectator";
import {Layout} from "../../Layout";
import {themeGetter} from "../../Theme";

export const QuestionPreviewModal = (props: {
    question: QuizQuestionsItem
    onClose: () => void,
    isOpen: boolean
}) => {
    const [instance, setInstance] = useState<QuizInstanceQuestionsItem | undefined>(undefined);

    useEffect(() => {
        if (props.isOpen) {
            elevateQuestionApiTemplatesQuestionsElevatePost(
                props.question
            ).then(r => {
                if ('data' in r) {
                    setInstance(r.data as QuizInstanceQuestionsItem)
                }
            })
        }
    }, [props.isOpen])

    if (!props.isOpen) {
        return null;
    }

    return (
        <QuestionInstancePreviewModal instance={instance} onClose={props.onClose} isOpen={props.isOpen}/>
    )
}


export const QuestionInstancePreviewModal = (props: {
    instance?: QuizInstanceQuestionsItem
    onClose: () => void,
    isOpen: boolean
}) => {
    if (!props.isOpen) {
        return null;
    }

    return (
        <Modal
            onClose={props.onClose}
            isOpen={props.isOpen}
            isCentered
            size='full'
        >
            <ModalOverlay/>
            <ModalContent bg={themeGetter().colors.quizycolor.background}>
                <ModalHeader>Question Preview</ModalHeader>
                <ModalBody>
                    {props.instance &&
                        <Layout sizeOverride={props.instance.question.displaySize} buttonHideOverride={true}
                                heightOverride={"80vh"} bgOverride={'transparent'}>
                            <SpectateQuestionInstance instance={props.instance}/>
                        </Layout>
                    }
                    {!props.instance &&
                        <Loading/>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button onClick={props.onClose}>Ok</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
