import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface CorrectnessState {
}

const initialState: CorrectnessState = {};

const correctnessSlice = createSlice({
    name: 'correctness',
    initialState,
    reducers: {
        setValue: (state, action: PayloadAction<{ team: string, question: string, value: number }>) => {
            if (!(action.payload.team in state)) {
                state[action.payload.team] = {}
            }

            state[action.payload.team][action.payload.question] = action.payload.value;
            return state;
        },
        setCorrect: (state, action: PayloadAction<{ team: string, question: string }>) => {
            if (!(action.payload.team in state)) {
                state[action.payload.team] = {}
            }

            state[action.payload.team][action.payload.question] = 1;
            return state;
        },
        setIncorrect: (state, action: PayloadAction<{ team: string, question: string }>) => {
            if (!(action.payload.team in state)) {
                state[action.payload.team] = {}
            }

            state[action.payload.team][action.payload.question] = 0;
            return state;
        },
        resetCorrectness: (state, action: PayloadAction<{ team: string, question: string }>) => {
            if (!(action.payload.team in state)) {
                state[action.payload.team] = {}
            }

            delete state[action.payload.team][action.payload.question];
            return state;
        },
    },
})

export const {setValue, setCorrect, setIncorrect, resetCorrectness} = correctnessSlice.actions

export default correctnessSlice.reducer
