import React, {useEffect, useState} from "react";
import {
    AbsoluteCenter,
    Box,
    Button,
    Center, Flex,
    Heading,
    HStack, Icon,
    IconButton,
    Spacer,
    useColorMode
} from "@chakra-ui/react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {BsArrowLeft} from "react-icons/bs";
import {AnimateMount} from "./Module/Utils/Animation";
import {DisplaySize} from "./Client/Models";
import {useAppDispatch, useAppSelector} from "./Store/Hooks";
import {decreaseFontSize, increaseFontSize} from "./Store/Features/FontReducer";
import {BiMinus, BiPlus} from "react-icons/bi";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {RiHammerFill, RiPlayFill} from "react-icons/ri";

interface LayoutContextType {
    size: DisplaySize,
    setSize: (d: DisplaySize) => void,
    hideBack: boolean,
    setHideBack: (b: boolean) => void
}

export const LayoutContext = React.createContext<LayoutContextType>({
    size: DisplaySize.NORMAL,
    setSize: (d) => {
    },
    hideBack: false,
    setHideBack: (b) => {
    }
});

export const Layout = (props: {
    sizeOverride?: DisplaySize,
    buttonHideOverride?: boolean,
    heightOverride?: string,
    bgOverride?: string,
    children?: React.ReactNode
}) => {
    const dispatch = useAppDispatch();
    const team = useAppSelector((state) => state.team.team);
    const [size, setSize] = useState<DisplaySize>(DisplaySize.NORMAL);
    const layoutContext = {
        size: size,
        setSize: setSize
    } as LayoutContextType

    useEffect(() => {
        setSize(DisplaySize.NORMAL)
    }, [setSize])

    const {colorMode} = useColorMode()
    const navigate = useNavigate();
    const location = useLocation();
    const settings = useAppSelector((state) => state.settings);

    const hideOverride = props.buttonHideOverride === null ? false : props.buttonHideOverride!
    const hideBack =
        location.pathname === "/" || location.pathname === "/admin" // Not on home pages
        || hideOverride
        || (settings.hideBackButton && location.pathname === "/quiz")

    const state = location.state;

    let finalSize: DisplaySize = size;
    const stateSize = state && state.size;
    if (stateSize && stateSize !== "") {
        finalSize = stateSize;
    }

    if (props.sizeOverride) {
        finalSize = props.sizeOverride;
    }

    const widthLG = (function () {
        switch (finalSize) {
            case "TINY":
                return "50%"
            case "SMALL":
                return "60%"
            case "NORMAL":
                return "70%"
            case "LARGE":
                return "80%"
            case "XL":
                return "90%"
            case "XXL":
                return "100%"
            case "FULL_SCREEN":
                return "100%"
        }
    })();

    const widthXL = (function () {
        switch (finalSize) {
            case "TINY":
                return "20%"
            case "SMALL":
                return "30%"
            case "NORMAL":
                return "50%"
            case "LARGE":
                return "70%"
            case "XL":
                return "80%"
            case "XXL":
                return "90%"
            case "FULL_SCREEN":
                return "100%"
        }
    })();

    const maxH = (function () {
        switch (finalSize) {
            case "TINY":
            case "SMALL":
            case "NORMAL":
            case "LARGE":
            case "XL":
            case "XXL":
                return "75vh"
            case "FULL_SCREEN":
                return "100%"
        }
    })();

    return (
        <Box
            background={props.bgOverride ? props.bgOverride : 'quizycolor.background'}
            minH={props.heightOverride ? props.heightOverride : "100vh"}
        >
            <Flex position='fixed' top={5} right={5} zIndex={2}>
                <HStack>
                    <IconButton
                        icon={<BiMinus/>}
                        size={'xs'}
                        fontSize={16}
                        aria-label={"Decrease font size"}
                        variant={'outline'}
                        onClick={() => {
                            dispatch(decreaseFontSize())
                        }
                        }
                    />
                    <IconButton
                        icon={<BiPlus/>}
                        size={'xs'}
                        fontSize={16}
                        aria-label={"Increase font size"}
                        variant={'outline'}
                        onClick={() => {
                            dispatch(increaseFontSize())
                        }
                        }
                    />
                </HStack>
            </Flex>
            <ToastContainer
                theme={colorMode}
                position={'bottom-center'}
                autoClose={7000}
                hideProgressBar={true}
                closeOnClick={true}
                pauseOnHover={false}
                pauseOnFocusLoss={false}
                draggable={true}
            />
            <AbsoluteCenter
                display='flex'
                flexDirection='column'
                overflow={'hidden'}
                p={10}
                h={finalSize === "FULL_SCREEN" ? '100%' : ''}
                w={{base: '100%', xl: widthXL, lg: widthLG, md: "100%"}}>
                <HStack py={3}>
                    {!hideBack &&
                        <Button
                            size={'sm'}
                            variant='outline'
                            aria-label='Terug'
                            leftIcon={<BsArrowLeft/>}
                            onClick={() => navigate(-1)}
                        >
                            Terug
                        </Button>
                    }
                    <Spacer/>
                    {location.pathname === "/quiz" && team && team._id !== "" &&
                        <Center>
                            <Heading size='sm'>
                                {`Team: ${team.name}`}
                            </Heading>
                        </Center>
                    }
                    {location.pathname === "/admin/builder" &&
                        <Center>
                            <Icon
                                aria-label='Hammer'
                                mx={2}
                                as={RiHammerFill}/>
                            <Heading size='sm'>
                                Quiz Builder
                            </Heading>
                        </Center>
                    }
                    {location.pathname === "/admin/runner" &&
                        <Center>
                            <Icon
                                aria-label='Play'
                                mx={2}
                                as={RiPlayFill}/>
                            <Heading size='sm'>
                                Quiz Runner
                            </Heading>
                        </Center>
                    }
                </HStack>
                <AnimateMount>
                    <Box p={2}
                         borderRadius='lg'
                         bg='quizycolor.light'
                         boxShadow='lg'
                         h={'90%'}
                         minH={"100px"}>
                        <Box
                            maxH={maxH}
                            h={finalSize === "FULL_SCREEN" ? '100%' : ''}
                            p={3}
                            overflowY={'auto'}
                        >
                            <LayoutContext.Provider value={layoutContext}>
                                <Outlet/>
                                {props.children}
                            </LayoutContext.Provider>
                        </Box>
                    </Box>
                </AnimateMount>
            </AbsoluteCenter>
        </Box>
    )
}
