import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";

interface SettingsState {
    uuid: string,
    hideBackButton: boolean
}

const initialState: SettingsState = {
    uuid: uuid(),
    hideBackButton: false
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setHideBackButton: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                hideBackButton: action.payload
            }
        },
    },
})

export const {setHideBackButton} = settingsSlice.actions

export default settingsSlice.reducer
