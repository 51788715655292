import {
    GTDQuestion,
    QuizQuestionsItem
} from "../../../../../Client/Models";
import React from "react";
import {Checkbox, Input, VStack} from "@chakra-ui/react";

const GuessTheDefinitionQuestionEditor = (props: {
    question: GTDQuestion,
    onSetQuestion: (question: QuizQuestionsItem) => void
    children?: React.ReactNode
}) => {
    return (
        <VStack w={"100%"} align={"left"}>
            <Input
                placeholder='Question name'
                value={props.question.name}
                onChange={(e) =>
                    props.onSetQuestion({...props.question, name: e.target.value})
                }
            />
            <Input
                placeholder='Word'
                value={props.question.word}
                onChange={(e) =>
                    props.onSetQuestion({...props.question, word: e.target.value})
                }
            />
            <Input
                placeholder='Definition'
                value={props.question.definition}
                onChange={(e) =>
                    props.onSetQuestion({...props.question, definition: e.target.value})
                }
            />
        </VStack>
    )
}

export {
    GuessTheDefinitionQuestionEditor
}
