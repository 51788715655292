import {AnimatePresence, motion} from "framer-motion";
import React from "react";

const AnimateMount = (props: {
    customKey?: string,
    children?: React.ReactNode
}) => {
    return (
        <AnimatePresence mode="popLayout">
            <motion.div
                style={{height: '100%'}}
                key={props.customKey !== undefined ? props.customKey : window.location.pathname}
                initial={{opacity: 0, y: 50}}
                animate={{opacity: 1, y: 0}}
            >
                {props.children}
            </motion.div>
        </AnimatePresence>
    )
}

export {
    AnimateMount
}
