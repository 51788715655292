import {Layout} from "./Layout";
import {TeamSelector} from "./Module/TeamSelector";
import {Quiz} from "./Module/Quiz/Quiz";
import {AdminHome} from "./Module/Admin/AdminHome";
import {TeamManager} from "./Module/Admin/Modules/TeamManager/TeamManager";
import {QuizBuilder} from "./Module/Admin/Modules/QuizBuilder/QuizBuilder";
import {QuizRunner} from "./Module/Admin/Modules/QuizRunner/QuizRunner";
import {createBrowserRouter} from "react-router-dom";
import React from "react";
import {WebSocketProvider} from "./WebSocket/WebSocketProvider";
import {Spectator} from "./Module/Quiz/Spectator";

const routes = [{
    element: <WebSocketProvider><Layout/></WebSocketProvider>,
    children: [
        {
            path: "/",
            element: (
                <TeamSelector/>
            ),
        },
        {
            path: "/quiz",
            element: (
                <Quiz/>
            ),
        },
        {
            path: "/spectator",
            element: (
                <Spectator/>
            ),
        },
        {
            path: "/admin",
            children: [
                {
                    path: "",
                    element: (
                        <AdminHome/>
                    ),
                },
                {
                    path: "teams",
                    element: (
                        <TeamManager/>
                    )
                },
                {
                    path: "builder",
                    element: (
                        <QuizBuilder/>
                    )
                },
                {
                    path: "runner",
                    element: (
                        <QuizRunner/>
                    )
                }
            ]
        },
    ]
}];

const router = createBrowserRouter(routes);

export {
    routes,
    router
}
