/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Quizy backend
 * OpenAPI spec version: 0.1.0
 */

/**
 * An enumeration.
 */
export type DisplaySize = typeof DisplaySize[keyof typeof DisplaySize];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DisplaySize = {
  TINY: 'TINY',
  SMALL: 'SMALL',
  NORMAL: 'NORMAL',
  LARGE: 'LARGE',
  XL: 'XL',
  XXL: 'XXL',
  FULL_SCREEN: 'FULL_SCREEN',
} as const;
