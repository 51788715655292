import {configureStore} from '@reduxjs/toolkit'
import {persistCombineReducers} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import fontReducer from "./Features/FontReducer";
import settingsReducer from "./Features/SettingsReducer";
import teamReducer from "./Features/TeamReducer";
import correctnessReducer from "./Features/CorrectnessReducer";

const persistConfig = {
    key: 'root',
    storage,
}

const rootReducer = persistCombineReducers(
    persistConfig,
    {
        font: fontReducer,
        team: teamReducer,
        settings: settingsReducer,
        correctness: correctnessReducer
    }
);

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
