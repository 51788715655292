import {
    baseTheme,
    createMultiStyleConfigHelpers,
    extendTheme,
    withDefaultColorScheme
} from "@chakra-ui/react";
import { modalAnatomy as parts } from '@chakra-ui/anatomy'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
    dialog: {
        bg: baseTheme.colors.orange["500"],
    },
});

const modalMultiTheme = defineMultiStyleConfig({
    baseStyle,
})

export const themeGetter = (customProps?: any) => extendTheme({
        components: {
            Modal: modalMultiTheme
        },
        colors: {
            quizycolor : {
                background: baseTheme.colors.orange["800"],
                verylight: baseTheme.colors.orange["600"],
                light: baseTheme.colors.orange["500"],
                mediumlight: baseTheme.colors.orange["300"],
                medium: baseTheme.colors.orange["200"],
                dark: baseTheme.colors.orange["100"]
            }
        },
        fonts: {
            heading: 'Roboto',
            body: 'Roboto',
        },
        config: {
            initialColorMode: 'dark',
            useSystemColorMode: false,
        },
        ...customProps,
    },
    withDefaultColorScheme({colorScheme: 'orange'})
)
